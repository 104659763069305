import React, { useEffect, useState } from "react";
import mapPinIcon from "../../assets/icons/map-pin.svg";
import DetailPoi from "../DetailPoi";
import noSearchIcon from "../../assets/no-search.svg";
import ImageLoader from "../ImageLoader";
import { getPoiAsset } from "../../helper";

const PaginatedGridPoi = ({ pois = [], assetUrl }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [detailPoi, setDetailPoi] = useState(null);
  const itemsPerPage = 16;
  const totalPages = Math.max(1, Math.ceil((pois?.length || 0) / itemsPerPage));

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = pois?.slice(indexOfFirstItem, indexOfLastItem) || [];

  const handlePageChange = (pageNumber) => {
    if (pageNumber === 0 || pageNumber > totalPages) return;
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    if (pois && pois.length > 0) {
      if (currentPage > totalPages) {
        setCurrentPage(totalPages);
      }
    } else {
      setCurrentPage(1);
    }
  }, [pois]);

  useEffect(() => {
    if (detailPoi) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }

    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, [detailPoi]);

  return (
    <div className="gap-y-4 mt-8">
      {pois?.length === 0 ? (
        <div className="w-full">
          <div className="flex flex-col items-center justify-center mx-auto px-4">
            <img src={noSearchIcon} alt="No Results" />
            <span className="font-semibold text-base md:text-2xl text-center w-[300px]">
              No Result Found
            </span>
            <span className="text-sm md:text-xl text-center w-[200px] md:w-[400px]">
              Sorry, try adjusting your search to find what you are looking for.
            </span>
          </div>
        </div>
      ) : (
        <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
          {currentItems.map((poi, index) => (
            <div
              key={indexOfFirstItem + index}
              className="flex flex-col bg-[#F8F8F8] p-[6px] md:p-4 rounded-xl gap-y-4 cursor-pointer"
              onClick={() => setDetailPoi(poi)}
            >
              <ImageLoader
                src={
                  poi?.logo
                    ? getPoiAsset(poi?.logo)
                    : require("../../assets/default.png")
                }
                className="w-full h-28 md:h-[240px] object-fill rounded-xl"
                alt={poi.name}
              />
              <span className="text-xs md:text-xl font-bold line-clamp-2 text-[#1F284E]">
                {poi.name}
              </span>
              <div className="flex flex-row gap-x-2">
                <img
                  src={mapPinIcon}
                  className="h-3 w-4 md:w-4 md:h-4"
                  alt="Location"
                />
                <span className="text-[10px] md:text-sm line-clamp-2 text-[#1F284E]">
                  # {poi.location}
                </span>
              </div>
              <div className="text-[8px] md:text-base w-max rounded-full px-3 py-1 md:px-6 md:py-3 bg-[rgba(80,168,90,0.12)] text-[#50A85A] font-semibold">
                {poi.categoryName ?? "-"}
              </div>
            </div>
          ))}
        </div>
      )}

      {pois?.length > 0 && (
        <div className="flex flex-wrap justify-center gap-2 mt-4">
          <div
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
            className={`flex w-8 h-8 md:w-12 md:h-12 rounded border border-[#DFE3E8] items-center justify-center ${
              currentPage === 1
                ? "bg-[#919EAB] cursor-not-allowed opacity-50"
                : "bg-white cursor-pointer"
            }`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="26"
              height="26"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              color={"#C4CDD5"}
            >
              <path d="M15 18l-6-6 6-6"></path>
            </svg>
          </div>

          {/* Page Numbers (Condensed on Small Screens) */}
          {[...Array(totalPages)].map((_, index) => {
            const pageNumber = index + 1;

            return (
              <button
                key={index}
                onClick={() => handlePageChange(pageNumber)}
                className={`w-8 h-8 md:w-12 md:h-12 rounded ${
                  currentPage === pageNumber
                    ? "bg-[#50A85A1F] text-[#50A85A] border border-[#50A85A] text-xl font-semibold"
                    : "bg-white border border-[#DFE3E8] text-black text-xl font-semibold"
                }`}
              >
                <span className="text-base md:text-xl">{pageNumber}</span>
              </button>
            );
          })}

          <div
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
            className={`flex w-8 h-8 md:w-12 md:h-12 rounded border border-[#DFE3E8] items-center justify-center ${
              currentPage === totalPages
                ? "bg-[#919EAB] cursor-not-allowed opacity-50"
                : "bg-white cursor-pointer"
            }`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="26"
              height="26"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              color={"#C4CDD5"}
            >
              <path d="M9 18l6-6-6-6"></path>
            </svg>
          </div>
        </div>
      )}

      {detailPoi && (
        <DetailPoi
          onClose={() => {
            setDetailPoi(null);
          }}
          poi={detailPoi}
        />
      )}
    </div>
  );
};

export default PaginatedGridPoi;
