const ArrowRightSlider = ({ color, strokeWidth = "2" }) => {
  return (
    <svg
      width="200"
      height="100"
      viewBox="0 0 200 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M147.202 30L168 49.9979M168 49.9979L147.202 70M168 49.9979H32"
        stroke={color}
        stroke-width={strokeWidth}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
    // <svg
    //   width="138"
    //   height="42"
    //   viewBox="0 0 138 42"
    //   fill="none"
    //   xmlns="http://www.w3.org/2000/svg"
    // >
    //   <path
    //     d="M116.202 1L137 20.9979M137 20.9979L116.202 41M137 20.9979H1"
    //     stroke={color}
    //     stroke-width={strokeWidth}
    //     stroke-linecap="round"
    //     stroke-linejoin="round"
    //   />
    // </svg>
  );
};

export default ArrowRightSlider;
