import React, { useState } from "react";

const ImageLoader = ({ src, alt, className }) => {
  const [loading, setLoading] = useState(true);

  return (
    <div className={`relative ${className}`}>
      {/* Skeleton Placeholder */}
      {loading && (
        <div
          className={`absolute inset-0 bg-gray-500 animate-pulse ${className}`}
        ></div>
      )}

      {/* Actual Image */}
      {src && (
        <img
          src={src}
          onLoad={() => setLoading(false)}
          alt={!loading ? alt : ""}
          className={`transition-opacity duration-500 pointer-events-none touch-none
            ${loading ? "opacity-0" : "opacity-100"} ${className}`}
        />
      )}
    </div>
  );
};

export default ImageLoader;
