import React from "react";
import ReactDOM from "react-dom";
import { ReactComponent as X } from "../../assets/icons/detail-poi-close.svg";
import { ReactComponent as Lift } from "../../assets/icons/detail-poi-lift.svg";
import { ReactComponent as Escalator } from "../../assets/icons/detail-poi-escalator.svg";
import { ReactComponent as CalendarIcon } from "../../assets/icons/Calendar_Week.svg";
import { getMediaAsset, getPoiAsset } from "../../helper";
import { useNavigate } from "react-router-dom";

const DetailEvent = ({
  data,
  onClose,
  wayfindingByEscalator,
  wayfindingByLift,
  onOpenTnc,
}) => {
  const navigate = useNavigate();
  if (!data) return null;

  // Close modal when clicking outside
  const handleOutsideClick = (e) => {
    if (e.target.id === "modal-overlay") {
      onClose();
    }
  };

  const parseText = (inputText) =>
    inputText.split(/<a>(.*?)<a>/g).map((part, index) =>
      index % 2 === 1 ? (
        <span
          key={index}
          onClick={onOpenTnc}
          className="text-blue-600 underline hover:text-blue-800 cursor-pointer"
        >
          {part}
        </span>
      ) : (
        part
      )
    );

  return (
    <div
      id="modal-overlay"
      className="fixed inset-0 z-40 bg-black bg-opacity-50 flex items-center justify-center"
      onClick={handleOutsideClick}
      style={{ margin: 0 }}
    >
      <div className="bg-white absolute w-[90%] md:w-[80%] h-max bg-white overflow-y-auto rounded-2xl shadow-lg m-32">
        <div className="p-4 md:p-8">
          {/* Close Button */}
          <button
            className="absolute top-3 right-3 text-gray-400 hover:text-gray-600"
            onClick={onClose}
          >
            <X size={24} />
          </button>

          {/* Content Section */}
          <div className="flex flex-col md:flex-row gap-4 overflow-y-auto">
            {/* Image Placeholder */}
            <div className="w-full h-[200px] md:w-[400px] md:h-[300px] rounded-lg flex items-center justify-center">
              <img
                src={
                  data.logo
                    ? getMediaAsset(data.logo)
                    : require("../../assets/dummy-poi.png")
                }
                alt="Tenant"
                className="w-full h-full object-fill rounded-xl"
              />
            </div>

            {/* Details */}
            <div className="flex-1 basis-3/4">
              <span className="text-xl md:text-[32px] font-semibold">
                {data?.name}
              </span>

              <div className="flex flex-row items-center text-[#1F284E] text-sm font-bold mt-6">
                <CalendarIcon size={16} className="mr-1" />
                <span className="text-base md:text-xl font-semibold">
                  {data?.eventSchedule}
                </span>
              </div>
              <p className="text-gray-600 text-xs md:text-sm mt-3">
                {data.description}
              </p>
              <p className="text-gray-600 text-xs md:text-sm mt-3">
                {data?.url && parseText(data?.url)}
              </p>
            </div>
          </div>
        </div>

        {/* Footer Buttons */}
        {data?.associatedPois && data?.associatedPois.length > 0 && (
          <div className="grid grid-cols-2">
            <button
              onClick={() => {
                wayfindingByEscalator();
                onClose();
              }}
              className="flex items-center justify-center gap-2 bg-[#3375AE] text-white text-sm font-semibold p-3 rounded-bl-lg"
            >
              <Escalator className="size-12" />
              ESCALATOR ACCESS
            </button>
            <button
              onClick={() => {
                wayfindingByLift();
                onClose();
              }}
              className="flex items-center justify-center gap-2 bg-[#C03C8F] text-white text-sm font-semibold p-3 rounded-br-lg"
            >
              <Lift className="size-12" />
              LIFT ACCESS
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default DetailEvent;
