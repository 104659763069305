const ArrowLeftSlider = ({ color, strokeWidth = "2" }) => {
  return (
    <svg
      width="200"
      height="100"
      viewBox="0 0 200 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M52.7982 30L32 49.9979M32 49.9979L52.7982 70M32 49.9979H168"
        stroke={color}
        stroke-width={strokeWidth}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default ArrowLeftSlider;
