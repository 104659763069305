import { ReactComponent as ArrowRight } from "../../assets/icons/arrow-right-white.svg";

const FormSubmitButton = ({ fullWidth, onClick }) => {
  return (
    <button
      onClick={onClick}
      className={`${
        fullWidth ? "w-full" : "w-fit"
      } bg-[#50A85A] text-white md:text-lg font-semibold py-3 px-4 md:py-5 md:px-6 rounded-full flex items-center justify-between md:justify-center gap-2`}
    >
      Leave us a Message <ArrowRight />
    </button>
  );
};

export default FormSubmitButton;
