export const tagsConstant = [
  {
    id: "67b53abe8027ddff79b7c063",
    name: "coffee",
  },
];

export const captchaConstant = {
  sitekey: "6Le7LfoqAAAAACblWDqOdg08NKRLB2DRs2s5iykM",
};

export const getRecipient = (type) => {
  switch (type) {
    case "General Enquiry":
      return "promotions@orchardgateway.sg";
    case "Advertising & Event Space Enquiry":
      return "promotions@orchardgateway.sg";
    case "Leasing Enquiry":
      return "leasing@orchardgateway.sg";
    case "Season Parking Enquiry":
      return "operations@orchardgateway.sg";
    default:
      return "promotions@orchardgateway.sg";
  }
};
