import React from "react";
import { getInformationAsset } from "../../helper";
import ImageLoader from "../ImageLoader";

const SlidersAboutUs = ({ scrollContainerRef, imagesUrl }) => {
  const placeholders = [
    {
      id: 1,
      src: "../../assets/dummy-about-us-slider.jpg",
      alt: "Image 1",
    },
    {
      id: 2,
      src: "../../assets/dummy-about-us-slider.jpg",
      alt: "Image 2",
    },
    {
      id: 3,
      src: "../../assets/dummy-about-us-slider.jpg",
      alt: "Image 3",
    },
  ];

  return (
    <div className="w-full">
      <div
        ref={scrollContainerRef}
        className="flex overflow-x-auto scrollbar-hide scroll-smooth gap-4 px-8 md:px-[72px]"
        style={{ scrollbarWidth: "none", msOverflowStyle: "none" }}
      >
        <style jsx>{`
          .scrollbar-hide::-webkit-scrollbar {
            display: none;
          }
        `}</style>

        {imagesUrl?.length > 0
          ? imagesUrl.map((imageUrl, index) => (
              <div
                key={index}
                className="flex-none size-1/2 max-w-[200px] md:max-w-[300px] lg:max-w-full aspect-[497/662] rounded-xl overflow-hidden relative"
              >
                <ImageLoader
                  src={getInformationAsset(imageUrl?.logo)}
                  className="size-full object-cover"
                  alt={imageUrl.logo}
                />
              </div>
            ))
          : placeholders.map((image, index) => (
              <div
                key={index}
                className="flex-none w-[150px] md:w-[200px] lg:size-1/2 aspect-[497/662] rounded-xl overflow-hidden relative"
              >
                <ImageLoader
                  src={image.src}
                  className="size-full object-cover"
                  alt={image.alt}
                />
              </div>
            ))}
      </div>
    </div>
  );
};

export default SlidersAboutUs;
