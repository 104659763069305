import React, { useEffect, useState } from "react";
import calendarIcon from "../../assets/icons/Calendar.svg";
import noEventIcon from "../../assets/no-event.svg";
import { getMediaAsset } from "../../helper";
import VideoPlayer from "../Video";
import DetailEvent from "../DetailEvent";
import ImageLoader from "../ImageLoader";

const PaginatedGridWhatsOn = ({ data = [] }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const itemsPerPage = 4;
  const now = new Date();

  const totalPages = Math.max(1, Math.ceil((data?.length || 0) / itemsPerPage));

  // to prevent body below detail poi can be scrolled
  useEffect(() => {
    if (selectedEvent) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }

    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, [selectedEvent]);

  const handlePageChange = (pageNumber) => {
    if (pageNumber === 0 || pageNumber > totalPages) return;
    setCurrentPage(pageNumber);
  };

  return (
    <div className="space-y-4">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4 md:gap-4 xl:gap-10">
        {data?.map((content, index) => {
          if (!content?.start || !content?.end) return false;
          else {
            const convertedStartDate = new Date(content?.start);
            const convertedEndDate = new Date(content?.end);
            if (now > convertedStartDate && now < convertedEndDate)
              return (
                <div
                  key={`${index}`}
                  className="relative"
                  onClick={() => setSelectedEvent(content)}
                >
                  {content?.fileType === "image/png" ||
                  content?.fileType === "image/jpeg" ||
                  content?.fileType === "image/jpg" ? (
                    <ImageLoader
                      src={getMediaAsset(content?.logo)}
                      className="w-full h-[250px] md:h-[490px] mb-2 object-cover object-center rounded-xl"
                    />
                  ) : content?.fileType === "video/mp4" ? (
                    <VideoPlayer className="w-full h-[250px] md:h-[490px] mb-2 object-cover object-center rounded-xl" />
                  ) : (
                    <div className="w-full h-[250px] md:h-[490px] mb-2 object-fill rounded-xl bg-black"></div>
                  )}
                  <div className="absolute bottom-0 bg-gradient-to-b from-transparent to-black w-full h-[60%] place-content-end pl-3 md:pl-6 rounded-b-xl">
                    <div className="flex flex-row justify-between place-content-end">
                      <div className="flex flex-col justify-between py-2 gap-y-1.5 md:gap-y-3 mb-2 md:mb-4">
                        <span className="text-sm md:text-2xl font-bold line-clamp-2 text-white">
                          {content?.name}
                        </span>
                        <div className="flex flex-row gap-x-2 items-center">
                          <img
                            src={calendarIcon}
                            className="w-3 h-3 md:h-4 md:w-4"
                          />
                          <span className="text-[10px] md:text-sm font-semibold text-white">
                            {content?.eventSchedule}
                          </span>
                        </div>
                      </div>
                      <div className="flex flex-col justify-end">
                        <img
                          src={require("../../assets/btn-next.png")}
                          className="w-10 h-10 md:w-16 md:h-16 rounded-br-xl"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              );
          }
        })}
      </div>
      {(!data || data?.length < 1) && (
        <div className="flex flex-col items-center justify-center h-[calc(100%-10rem)] w-full mr-4 md:mr-20 mt-4">
          <img src={noEventIcon} />
          <span className="font-semibold text-base md:text-2xl text-center w-[300px]">
            No Happenings here yet!
          </span>
          <span className="text-sm md:text-xl text-center px-4 md:w-[600px]">
            It looks like there are no events scheduled at the moment. Check
            back soon for exciting happenings!
          </span>
        </div>
      )}
      {selectedEvent && (
        <DetailEvent
          data={selectedEvent}
          onClose={() => setSelectedEvent(null)}
        />
      )}
    </div>
  );
};

export default PaginatedGridWhatsOn;
