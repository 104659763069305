import React, { useState } from "react";

const Dropdown = ({
  options,
  selected,
  setSelected,
  placeHolder,
  title,
  multiSelect = false,
  className,
  valueToShow,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const checkItemExists = (newItem) => {
    const exists = selected.some((item) => item.name === newItem.name);
    return exists;
  };

  return (
    <div className={`relative w-64 ${className}`}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="w-full flex items-center justify-between pl-4 pr-2 w-62 h-12 rounded-full text-gray-700 border border-gray-300 bg-[#F7F7F7]"
      >
        <p className="text-[#0A2D67] truncate ...">
          <span className="font-medium">{title} : </span>
          <span className="font-bold">{valueToShow ?? selected?.name}</span>
        </p>

        <svg
          width="20"
          height="20"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className={`transition-transform duration-200 ${
            isOpen ? "rotate-180" : ""
          }`}
        >
          <polyline points="6 9 12 15 18 9"></polyline>
        </svg>
      </button>

      {isOpen && (
        <div className="absolute w-full mt-2 bg-white border border-gray-300 rounded-2xl shadow-lg z-10">
          {options.map((option, index) => (
            <button
              key={index}
              onClick={() => {
                if (multiSelect) {
                  if (option.name === "All Category") {
                    setSelected([option]);
                  } else {
                    setSelected((selected) => {
                      let tempSelected = selected;
                      const exists = tempSelected.some(
                        (item) => item.name === option.name
                      );

                      tempSelected = selected.filter(
                        (item) => item.name !== "All Category"
                      );

                      if (exists) {
                        return tempSelected.filter(
                          (item) => item.name !== option.name
                        );
                      } else {
                        return [...tempSelected, option];
                      }
                    });
                  }
                } else {
                  setSelected(option);
                }
                setIsOpen(false);
              }}
              className={`inline-flex w-full justify-between items-center px-4 py-2 text-left hover:bg-gray-50 first:rounded-t-2xl last:rounded-b-2xl ${
                (title !== "Sort By" && option === selected) ||
                (title === "Sort By" && option.name === selected.name) ||
                (title !== "Sort By" && multiSelect && checkItemExists(option))
                  ? "text-[#50A85A] font-bold bg-[rgba(80,168,90,0.06)]"
                  : "text-[#525252] font-semibold"
              }
              `}
            >
              {option.name}
              {(title !== "Sort By" && option === selected) ||
              (title === "Sort By" && option.name === selected.name) ||
              (title !== "Sort By" &&
                multiSelect &&
                checkItemExists(option)) ? (
                <svg
                  class="w-5 h-5 ml-2 text-[#50A85A]"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M5 13l4 4L19 7"
                  ></path>
                </svg>
              ) : (
                <></>
              )}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default Dropdown;
