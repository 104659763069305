import React from "react";
import { ReactComponent as X } from "../../assets/icons/detail-poi-close.svg";
import { ReactComponent as MailSuccessSent } from "../../assets/mail-sent.svg";
import { ReactComponent as FailMailSuccessSent } from "../../assets/fail-mail-sent.svg";

const ModalMailSent = ({ isSuccess, onClose }) => {
  // Close modal when clicking outside
  const handleOutsideClick = (e) => {
    if (e.target.id === "modal-overlay") {
      onClose();
    }
  };

  return (
    <div
      id="modal-overlay"
      className="fixed inset-0 z-40 bg-black bg-opacity-50 flex items-center justify-center"
      onClick={handleOutsideClick}
    >
      <div className="bg-white absolute w-[90%] md:w-2/5 h-max overflow-y-auto rounded-2xl shadow-lg m-32">
        <div className="p-2 md:p-6">
          {/* Close Button */}
          <button
            className="absolute top-3 right-3 text-gray-400 hover:text-gray-600"
            onClick={onClose}
          >
            <X size={24} />
          </button>

          {/* Content Section */}
          <div className="flex flex-col items-center justify-center gap-y-4 md:gap-x-4">
            {isSuccess ? <MailSuccessSent /> : <FailMailSuccessSent />}

            <span className="text-3xl font-bold text-center">
              {isSuccess
                ? "Your message has been successfully sent!"
                : "Something went wrong, and your message couldn’t be sent. Please try again."}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalMailSent;
