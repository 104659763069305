// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyClFojgBMf5d8UsfGZlAYW9AnTJfAEXL2c",
  authDomain: "ogw-revamp.firebaseapp.com",
  projectId: "ogw-revamp",
  storageBucket: "ogw-revamp.firebasestorage.app",
  messagingSenderId: "174885825567",
  appId: "1:174885825567:web:da81727ce1fdb5ab03792a",
  measurementId: "G-F7NCNKDZMX",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
// Utility function to log events
export const logAnalyticsEvent = (eventName, eventParams = {}) => {
  logEvent(analytics, eventName, eventParams);
};
export { analytics };
