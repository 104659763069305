import { AdsignMap, MapStore } from "@adactiveasia/adsign-map";
import React, { createContext, useEffect, useRef } from "react";
import { mapConfig, mapOption } from "./constants/mapConstant";
import { observer } from "mobx-react-lite";

const AdsignMapContext = createContext();

const AdsignMapProvider = observer(({ children }) => {
    const mapStore = useRef(new MapStore()).current;

    const MinimalMap = () => (
        <div className="hidden">
            <AdsignMap config={mapConfig} store={mapStore} />
        </div>
    );

    return (
        <AdsignMapContext.Provider value={mapStore}>
            <MinimalMap />
            {children}
        </AdsignMapContext.Provider>
    );
});

export { AdsignMapContext, AdsignMapProvider };
