import React, { useEffect, useState } from "react";
import { AdsignMapContext } from "../../adsignMapProvider";
import { tagsConstant } from "../../constants/appConstant";
import { logAnalyticsEvent } from "../../services/firebaseAnalytics";

const SearchView = ({
  assetUrl,
  query,
  onClose,
  setStartPoint,
  setEndPoint,
  isFillStartPoint,
  poisData,
}) => {
  const mapStore = React.useContext(AdsignMapContext);
  const [newPois, setNewPois] = useState([]);
  const [pois, setPois] = useState([]);

  useEffect(() => {
    if (poisData) {
      const poiList = poisData?.filter((poi) => {
        if (query) {
          const tag = tagsConstant.filter((tag) =>
            tag.name.toLowerCase().includes(query)
          );
          return (
            poi.name.toLowerCase().includes(query.toLowerCase()) ||
            (poi.categoryName &&
              poi.categoryName.toLowerCase().includes(query.toLowerCase())) ||
            (tag && tag.some((item) => poi.tags.includes(item.id)))
          );
        } else {
          return true;
        }
      });

      setPois(poiList);
    }
  }, [query, poisData]);

  return (
    <div
      id="modal-overlay"
      className="fixed inset-0 z-40 bg-transparent"
      onClick={onClose}
    >
      <div className="flex flex-col rounded rounded-lg border border-white p-4 w-full h-full max-w-[90%] md:max-w-[640px] max-h-[620px] absolute top-[7.5rem] md:top-[10.5rem] left-1/2 transform -translate-x-1/2 md:right-[30%] z-[60] bg-[#EEECEA] overflow-y-auto">
        <span className="text-[#556675] text-xs font-bold mb-2">Results</span>
        {pois?.map((poi) => (
          <div
            className="flex flex-row mt-4"
            onClick={() => {
              if (isFillStartPoint) {
                setStartPoint(poi);
                logAnalyticsEvent("destination", {
                  poiStart: `${poi.name}`,
                });
              } else {
                setEndPoint(poi);
                logAnalyticsEvent("destination", {
                  poiDestination: `${poi.name}`,
                });
              }
              onClose();
            }}
          >
            <div className="h-[84px] w-[124px]">
              <img
                src={
                  poi.logo
                    ? assetUrl + "/poi/" + poi.logo
                    : require("../../assets/dummy-poi.png")
                }
                className="w-full h-full object-fill object-center"
              />
            </div>

            <div className="flex flex-col w-full bg-white p-4 justify-between">
              <span className="text-[#556675] text-xs font-bold">
                {poi?.name}
              </span>
              <div className="flex flex-row justify-between w-full bg-white items-center">
                <span className="text-[#556675] text-xs font-bold">
                  # {poi.location}
                </span>
                <div className="text-[8px] md:text-xs w-max rounded-full px-3 py-1 bg-[rgba(80,168,90,0.12)] text-[#50A85A] font-semibold">
                  {poi.categoryName ?? "-"}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SearchView;
