import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { ReactComponent as X } from "../../assets/icons/detail-poi-close.svg";
import { ReactComponent as Lift } from "../../assets/icons/detail-poi-lift.svg";
import { ReactComponent as Escalator } from "../../assets/icons/detail-poi-escalator.svg";
import { ReactComponent as Location } from "../../assets/icons/detail-poi-location.svg";
import { ReactComponent as ArrowRight } from "../../assets/icons/arrow-right-white-large.svg";
import { getPoiAsset } from "../../helper";
import { useNavigate } from "react-router-dom";
import { checkPathBetweenShape } from "@adactiveasia/adsign-map";

const DetailPoi = ({
  poi,
  poiFrom,
  startShape,
  destinationShape,
  onClose,
  wayfindingByEscalator,
  wayfindingByLift,
  wayfindingByStandart,
  mapStore,
}) => {
  const navigate = useNavigate();
  const [isPathAvailable, setIsPathAvailable] = useState(false);
  const [isPathLiftAvailable, setIsPathLiftAvailable] = useState(false);
  const [isPathEscalatorAvailable, setIsPathEsclatorAvailable] =
    useState(false);
  const [isSameFloor, setIsSameFloor] = useState(false);

  if (!poi) return null;

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (poi !== null && mapStore) {
      checkPath(poi, mapStore);
    }
  }, [poi, mapStore]);

  // Close modal when clicking outside
  const handleOutsideClick = (e) => {
    if (e.target.id === "modal-overlay") {
      onClose();
    }
  };

  const checkPath = async (selectedPoi) => {
    const shapeTo = mapStore.freeShapes.find((data) => {
      if (selectedPoi.location.split(",")) {
        return selectedPoi.location.split(",").includes(data.name);
      }
      return (
        selectedPoi.location === data.name ||
        selectedPoi.location === "#" + data.name
      );
    });

    const shapeFrom = mapStore.freeShapes.find((data) => {
      if (poiFrom?.location.split(",")) {
        return poiFrom?.location.split(",").includes(data.name);
      }
      return (
        poiFrom?.location === data.name || poiFrom?.location === "#" + data.name
      );
    });

    // check path
    const standart = await checkPathBetweenShape(
      mapStore,
      startShape._id,
      destinationShape._id,
      "standart"
    );
    const lift = await checkPathBetweenShape(
      mapStore,
      startShape._id,
      destinationShape._id,
      "only-disabled"
    );
    const escalator = await checkPathBetweenShape(
      mapStore,
      startShape._id,
      destinationShape._id,
      "not-disabled"
    );

    if (destinationShape?.floorId === startShape.floorId) {
      setIsSameFloor(true);
    } else {
      setIsSameFloor(false);
    }

    if (destinationShape) {
      // set #select analytic
      mapStore.setCurrentFreeShape([shapeTo]);
      setIsPathAvailable(true);
      if (lift == true) {
        setIsPathLiftAvailable(true);
      }
      if (escalator == true) {
        setIsPathEsclatorAvailable(true);
      }
    } else {
      setIsPathAvailable(false);
      setIsPathEsclatorAvailable(false);
      setIsPathLiftAvailable(false);
    }
  };
  const checkLocation = (poi) => {
    const locations =
      mapStore.currentFreeShape && mapStore.currentFreeShape.length > 0
        ? mapStore.currentFreeShape[0].name
        : "Orchard Gateway";
    return locations;
  };

  return (
    <div
      id="modal-overlay"
      className="fixed inset-0 z-40 bg-black bg-opacity-50 flex items-center justify-center"
      onClick={handleOutsideClick}
    >
      <div className="absolute w-[90%] md:w-[80%] bg-white rounded-xl shadow-lg m-32">
        <div
          className="flex flex-row md:flex-col p-2 md:p-6 w-full h-[420px] md:h-[370px] md:overflow-y-scroll scrollbar-hide [&::-webkit-scrollbar]:hidden
                        [-ms-overflow-style:none]
                        [scrollbar-width:none]"
        >
          {/* Close Button */}
          <button
            className="absolute top-3 right-3 text-gray-400 hover:text-gray-600"
            onClick={onClose}
          >
            <X size={24} />
          </button>

          {/* Content Section */}
          <div className="flex flex-col md:flex-row gap-y-2 md:gap-x-4">
            {/* Image Placeholder */}
            <div className="w-full h-[240px] md:w-[450px] md:h-[300px] rounded-lg flex items-center justify-center">
              <img
                src={
                  poi.logo
                    ? getPoiAsset(poi.logo)
                    : require("../../assets/dummy-poi.png")
                }
                alt="Tenant"
                className="w-full h-full object-center object-fill rounded-xl"
              />
            </div>

            {/* Details */}
            <div
              className="flex-1 basis-3/4 px-4 gap-4 overflow-y-scroll scrollbar-hide [&::-webkit-scrollbar]:hidden
                        [-ms-overflow-style:none]
                        [scrollbar-width:none]"
            >
              <h2 className="text-base md:text-3xl font-semibold">
                {poi.name}
              </h2>
              <div className="flex items-center text-[#1F284E] font-bold mt-4 md:mt-6">
                <Location size={16} className="mr-1 h-3 w-3 md:h-4 md:w-4" />
                <span className="text-xs md:text-[20px]">
                  # {mapStore ? checkLocation(poi) : poi.location}
                </span>
              </div>
              <div className="bg-green-100 text-green-600 text-xs md:text-[20px] font-semibold rounded-full py-1 px-3 md:py-2 w-max mt-3">
                {poi.categoryName}
              </div>
              <p className="text-gray-600 text-xs md:text-2xl mt-3 md:mt-6">
                {poi.description}
              </p>
            </div>
          </div>
        </div>

        {/* Footer Buttons */}
        {mapStore && (
          <div className="grid grid-cols-[repeat(auto-fit,minmax(0,1fr))] md:h-[96px] rounded rounded-b-lg">
            {isPathAvailable ? (
              isSameFloor ? (
                <button
                  onClick={() => {
                    wayfindingByStandart();
                    onClose();
                  }}
                  className="flex items-center justify-center gap-2 bg-[#0D9E5B] text-white text-base md:text-2xl font-semibold rounded-b-lg p-3 md:p-0"
                >
                  GET DIRECTION
                  <ArrowRight
                    size={16}
                    className="mr-1 h-3 w-3 md:h-8 md:w-8"
                  />
                </button>
              ) : isPathEscalatorAvailable ? (
                <button
                  onClick={() => {
                    wayfindingByEscalator();
                    onClose();
                  }}
                  className="flex items-center justify-center gap-2 bg-[#3375AE] text-white text-xs md:text-2xl font-semibold rounded-bl-lg p-3 md:p-0"
                >
                  <Escalator className="size-8 md:size-12" />
                  ESCALATOR ACCESS
                </button>
              ) : null
            ) : null}
            {isPathAvailable && isPathLiftAvailable && !isSameFloor ? (
              <button
                onClick={() => {
                  wayfindingByLift();
                  onClose();
                }}
                className="flex items-center justify-center gap-2 bg-[#C03C8F] text-white text-xs md:text-2xl font-semibold rounded-br-lg p-3 md:p-0"
              >
                <Lift className="size-8 md:size-12" />
                LIFT ACCESS
              </button>
            ) : null}
          </div>
        )}
      </div>
    </div>
  );
};

export default DetailPoi;
