const TitleSection = ({ title, color, className }) => {
  return (
    <div
      className={`flex flex-col gap-y-1.5 md:gap-y-3 ${
        className ?? "mb-6 md:mb-1"
      }`}
    >
      <span className="text-xs md:text-lg lg:text-2xl text-[#1F284E] font-[Oplicio] tracking-wider uppercase">
        {title}
      </span>
      <hr class={`w-[36px] lg:w-[48px] border-t-2 ${color}`} />
    </div>
  );
};
export default TitleSection;
