import React from "react";
import VideoPlayer from "../../components/Video";
import { getMediaAsset } from "../../helper";
import ImageLoader from "../ImageLoader";

const InstagramContent = ({ whatsOnPageInstagram }) => {
  let col1 = "large";
  let col2 = "small";

  const renderImageGrid = (list) => {
    const pairs = [];
    for (let i = 0; i < list.length; i += 2) {
      // Pair two images, or use null if there's an odd image out
      pairs.push([list[i], list[i + 1] || null]);
    }
    const numberOfColumns = list.length / 2;
    const columns = [];
    for (let i = 0; i < pairs.length; i++) {
      const firstImgIndex = i % list.length;
      const secondImgIndex = (i + 2) % list.length;

      const isEvenColumn = i % 2 === 0;
      const firstImgHeight = isEvenColumn ? "h-[725px]" : "h-[300px]";
      const secondImgHeight = isEvenColumn ? "h-[300px]" : "h-[725px]";

      columns.push(
        <div
          key={`column-${i}`}
          className="flex flex-col gap-y-4 w-[25%] rounded rounded-xl"
        >
          <div className="relative rounded rounded-xl">
            {pairs?.[i]?.[0]?.fileType === "image/png" ||
            pairs?.[i]?.[0]?.fileType === "image/jpeg" ||
            pairs?.[i]?.[0]?.fileType === "image/jpg" ? (
              <ImageLoader
                src={getMediaAsset(pairs[i][0].logo)}
                className={`${firstImgHeight} object-cover w-full rounded rounded-xl`}
              />
            ) : pairs[i][0].fileType === "video/mp4" ? (
              <VideoPlayer
                className={`${firstImgHeight} w-full object-cover rounded rounded-xl`}
                src={getMediaAsset(pairs[i][0].logo)}
              />
            ) : (
              <div
                className={`${firstImgHeight} bg-black object-cover rounded rounded-xl`}
              ></div>
            )}
            <a href={pairs[i][0]?.url} target="_blank">
              <div className="absolute bottom-0 flex flex-row items-center w-full bg-gradient-to-b from-transparent to-black h-[60%] overflow-y-auto rounded rounded-xl no-scrollbar">
                <div
                  className={`p-4 overflow-y-auto rounded rounded-xl mt-auto`}
                >
                  <p className="text-white font-bold text-base mb-1">
                    {pairs[i][0]?.description?.split(/\r\n\r\n/)[0]}
                  </p>
                  <p
                    className="text-white text-xs"
                    style={{ whiteSpace: "pre-line" }}
                  >
                    {pairs[i][0]?.description
                      ?.split(/\r\n\r\n/)
                      ?.slice(1)
                      .join("\r\n\r\n")}
                  </p>
                </div>
              </div>
            </a>
          </div>
          {pairs[i][1] && (
            <div className="relative rounded rounded-xl">
              {pairs?.[i]?.[1]?.fileType === "image/png" ||
              pairs?.[i]?.[1]?.fileType === "image/jpeg" ||
              pairs?.[i]?.[1]?.fileType === "image/jpg" ? (
                <ImageLoader
                  src={getMediaAsset(pairs[i][1]?.logo)}
                  className={`${secondImgHeight} w-full object-cover rounded rounded-xl`}
                />
              ) : pairs[i][1]?.fileType === "video/mp4" ? (
                <VideoPlayer
                  className={`${secondImgHeight} w-full object-cover rounded rounded-xl`}
                  src={getMediaAsset(pairs[i][1]?.logo)}
                />
              ) : (
                <div
                  className={`${secondImgHeight} w-full bg-black object-cover rounded rounded-xl`}
                ></div>
              )}
              <a href={pairs[i][1]?.url} target="_blank">
                <div className="absolute bottom-0 flex flex-row items-center w-full bg-gradient-to-b from-transparent to-black h-[60%] overflow-y-auto rounded rounded-xl no-scrollbar">
                  <div
                    className={`p-4 overflow-y-auto rounded rounded-xl mt-auto `}
                  >
                    <p className="text-white font-bold text-base mb-1">
                      {pairs[i][1]?.description?.split(/\r\n\r\n/)[0]}
                    </p>
                    <p
                      className="text-white text-xs"
                      style={{ whiteSpace: "pre-line" }}
                    >
                      {pairs[i][1]?.description
                        ?.split(/\r\n\r\n/)
                        ?.slice(1)
                        .join("\r\n\r\n")}
                    </p>
                  </div>
                </div>
              </a>
            </div>
          )}
        </div>
      );
    }

    return columns;
  };

  return (
    <div>
      {/* mobile view */}
      <div className="flex flex-row mt-4 md:hidden ">
        <div className="flex flex-col gap-y-2 w-1/2 mr-2">
          {whatsOnPageInstagram?.map((instagramData, index) => {
            if (index === 0 || index % 2 === 0) {
              let size;
              let descSize;
              if (col1 === "large") {
                size = "w-full h-[342px]";
                col1 = "small";
                descSize = "h-[190px]";
              } else {
                size = "w-full h-[145px]";
                col1 = "large";
                descSize = "h-[95px]";
              }
              return (
                <div className="relative">
                  {(instagramData && instagramData.fileType === "image/png") ||
                  instagramData.fileType === "image/jpeg" ||
                  instagramData.fileType === "image/jpg" ? (
                    <ImageLoader
                      src={getMediaAsset(instagramData.logo)}
                      className={`${size} object-cover rounded rounded-md`}
                    />
                  ) : instagramData.fileType === "video/mp4" ? (
                    <VideoPlayer
                      className={`${size} object-cover rounded rounded-md`}
                      src={getMediaAsset(instagramData.logo)}
                    />
                  ) : (
                    <div
                      className={`${size} bg-black object-cover rounded rounded-md`}
                    ></div>
                  )}
                  <a href={instagramData?.url} target="_blank">
                    <div
                      className={`absolute bottom-0 flex flex-col justify-self-end w-full bg-gradient-to-b from-transparent to-black rounded rounded-md ${descSize}`}
                    >
                      <div
                        className={`p-2 overflow-y-auto rounded rounded-md mt-auto no-scrollbar`}
                      >
                        <p className="text-white font-bold text-[8px] mb-1">
                          {instagramData?.description?.split(/\r\n\r\n/)[0]}
                        </p>
                        <p
                          className="text-white text-[6px]"
                          style={{ whiteSpace: "pre-line" }}
                        >
                          {instagramData?.description
                            ?.split(/\r\n\r\n/)
                            ?.slice(1)
                            .join("\r\n\r\n")}
                        </p>
                      </div>
                    </div>
                  </a>
                </div>
              );
            }
            return <></>;
          })}
        </div>
        <div className="flex flex-col gap-y-2 w-1/2 ">
          {whatsOnPageInstagram?.map((instagramData, index) => {
            if (index % 2 !== 0) {
              let size2;
              let descSize;
              if (col2 === "small") {
                size2 = "w-full h-[145px]";
                col2 = "large";
                descSize = "h-[95px]";
              } else {
                size2 = "w-full h-[342px]";
                col2 = "small";
                descSize = "h-[190px]";
              }
              return (
                <div className="relative">
                  {instagramData?.fileType === "image/png" ||
                  instagramData?.fileType === "image/jpeg" ||
                  instagramData?.fileType === "image/jpg" ? (
                    <ImageLoader
                      src={getMediaAsset(instagramData.logo)}
                      className={`${size2} object-cover rounded rounded-md`}
                    />
                  ) : instagramData?.fileType === "video/mp4" ? (
                    <VideoPlayer
                      className={`${size2} object-cover rounded rounded-md`}
                      src={getMediaAsset(instagramData.logo)}
                    />
                  ) : (
                    <div
                      className={`${size2} bg-black object-cover rounded rounded-md`}
                    ></div>
                  )}
                  <a href={instagramData?.url} target="_blank">
                    <div
                      className={`absolute bottom-0 flex flex-col justify-self-end w-full bg-gradient-to-b from-transparent to-black rounded rounded-md ${descSize}`}
                    >
                      <div
                        className={`p-2 overflow-y-auto rounded rounded-md mt-auto no-scrollbar`}
                      >
                        <p className="text-white font-bold text-[8px] mb-1">
                          {instagramData?.description?.split(/\r\n/)[0]}
                        </p>
                        <p
                          className="text-white text-[6px]"
                          style={{ whiteSpace: "pre-line" }}
                        >
                          {instagramData?.description
                            ?.split(/\r\n\r\n/)
                            ?.slice(1)
                            .join("\r\n\r\n")}
                        </p>
                      </div>
                    </div>
                  </a>
                </div>
              );
            }
            return <></>;
          })}
        </div>
      </div>
      {/* desktop view */}
      <div className="hidden md:flex flex-row gap-x-4 mt-8 justify-center">
        {whatsOnPageInstagram && renderImageGrid(whatsOnPageInstagram)}
      </div>
    </div>
  );
};

export default InstagramContent;
