import axios from "axios";
import rateLimit from "axios-rate-limit";

const http = rateLimit(axios.create(), {
  maxRequests: 1,
  perMilliseconds: 5000,
});

const sendEmail = async (to, subject, body) => {
  try {
    const response = await http.post(
      "https://m0n5gwalzb.execute-api.ap-southeast-1.amazonaws.com/default/OWG-email-service",
      {
        to,
        subject,
        body,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: false,
      }
    );
    if (
      response.data.message === "Email sent successfully" ||
      response.data.message.toLowerCase().include("successfully")
    ) {
      return true;
    }

    return false;
  } catch (error) {
    console.log("Error Sending Email", error.response?.data || error.message);
    return false;
  }
};

const verifyCaptcha = async (captchaValue) => {
  const res = await fetch(
    "https://5up6lcj3xh.execute-api.ap-southeast-1.amazonaws.com/default/ogw-captcha-verificator",
    {
      method: "POST",
      body: JSON.stringify({ captchaValue }),
      headers: {
        "content-type": "application/x-www-form-urlencoded",
      },
    }
  );
  const data = await res.json();
  return data.success;
};

export { sendEmail, verifyCaptcha };
