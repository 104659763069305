import React, { useContext, useEffect, useRef, useState } from "react";
import Layout from "../../components/Layout";
import { observer } from "mobx-react-lite";
import bgSubHeader from "../../assets/bg-about-us-subheader.png";
import bgSection1 from "../../assets/bg-about-us-section1.png";
import { ReactComponent as ArrowRightSlider } from "../../assets/icons/arrow-right-slider2.svg";
import { ReactComponent as ArrowLeftSlider } from "../../assets/icons/arrow-left-slider3.svg";
import { ReactComponent as MRTActive } from "../../assets/icons/mrt-active.svg";
import { ReactComponent as MRT } from "../../assets/icons/MRT.svg";
import { ReactComponent as Bus } from "../../assets/icons/Bus.svg";
import { ReactComponent as BusActive } from "../../assets/icons/bus-active.svg";
import { ReactComponent as Car } from "../../assets/icons/Car.svg";
import { ReactComponent as CarActive } from "../../assets/icons/car-active.svg";
import { ReactComponent as Parking } from "../../assets/icons/Parking.svg";
import { ReactComponent as ParkingActive } from "../../assets/icons/parking-active.svg";

import SlidersAboutUs from "../../components/SlidersAboutUs";
import { extractTextFromTags, getInformationAsset } from "../../helper";
import { AdsignMapContext } from "../../adsignMapProvider";
import HtmlView from "../../components/HtmlView";
import ImageLoader from "../../components/ImageLoader";
import { loadAdsignData } from "@adactiveasia/adsign-map";
import { mapConfig } from "../../constants/mapConstant";
import { useLocation } from "react-router-dom";
import { logAnalyticsEvent } from "../../services/firebaseAnalytics";

const AboutUs = observer(() => {
  const scrollContainerRef = useRef();
  const mapStore = useContext(AdsignMapContext);
  const [headerData, setHeaderData] = useState(null);
  const [subHeaderData, setSubHeaderData] = useState(null);
  const [body1Data, setBody1Data] = useState(null);
  const [body2Data, setBody2Data] = useState(null);
  const [byBus, setByBus] = useState(null);
  const [byCar, setByCar] = useState(null);
  const [byMrt, setByMrt] = useState(null);
  const [carparkRates, setCarparkRates] = useState(null);
  const [nextSlideEnabled, setNextSlideEnabled] = useState(true);
  const [prevSlideEnabled, setPrevSlideEnabled] = useState(false);
  const [selectedGettingHere, setSelectedGettingHere] = useState(null);
  const [selectedGettingHereAsset, setSelectedGettingHereAsset] = useState("");
  const [selectedGettingHereAssetMobile, setSelectedGettingHereAssetMobile] =
    useState("");
  const [predefinedSectionName, setPredefinedSectionName] = useState("");
  const scrollByNumber = 300;
  const [dataAdsign, setDataAdsign] = useState([]);
  const location = useLocation();

  useEffect(() => {
    logAnalyticsEvent("destination", {
      page_title: `aboutUs`,
    });
    loadData();
  }, []);

  const loadData = async () => {
    const adsignData = await loadAdsignData(mapConfig, true);
    if (adsignData) {
      setDataAdsign(adsignData);
    }
  };

  useEffect(() => {
    // Extract the parameter from the URL (e.g., ?scrollTo=section1)
    const params = new URLSearchParams(location.search);
    const scrollTo = params.get("scrollTo"); // The value of the scrollTo param
    const sectionName = params.get("section");

    if (scrollTo) {
      const element = document.getElementById(scrollTo);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
    if (sectionName) {
      setPredefinedSectionName(sectionName);
    }
  }, [location]);

  // Fetch data from adsign
  useEffect(() => {
    if (
      dataAdsign &&
      dataAdsign.information &&
      dataAdsign.information.length > 0
    ) {
      const aboutUsData = dataAdsign.information
        .filter((info) => {
          return info.categoryName.includes("About Us Page");
        })
        .sort((a, b) => a.name.localeCompare(b.name));

      if (aboutUsData && aboutUsData.length > 0) {
        setBody1Data(aboutUsData[0] || null);
        setBody2Data(aboutUsData[1] || null);
        setHeaderData(
          (aboutUsData[2] &&
            aboutUsData[2].files &&
            aboutUsData[2].files[0] &&
            aboutUsData[2].files[0].logo) ||
            null
        );
        setSubHeaderData(
          (aboutUsData[3] && aboutUsData[3].description) || null
        );
        setByBus(aboutUsData[4] || null);
        setByCar(aboutUsData[5] || null);
        setByMrt(aboutUsData[6] || null);
        setCarparkRates(aboutUsData[8] || null);
        // predefined direction, from url
        switch (predefinedSectionName) {
          case "carpark":
            setSelectedGettingHere(aboutUsData[8] || null);
            return;
          default:
            break;
        }
      }
    }
  }, [dataAdsign, dataAdsign.information]);

  // Provide asset for each selectedGettinggHere
  useEffect(() => {
    if (selectedGettingHere) {
      if (selectedGettingHere.files && selectedGettingHere?.files[0]?.logo) {
        setSelectedGettingHereAsset(selectedGettingHere.files[0].logo);
      }
      if (selectedGettingHere.files && selectedGettingHere?.files[1]?.logo) {
        setSelectedGettingHereAssetMobile(selectedGettingHere.files[1].logo);
      }
    }
  }, [selectedGettingHere]);

  // Initiate selectedGettingHere to select By MRT
  useEffect(() => {
    if (byMrt && !selectedGettingHere) {
      setSelectedGettingHere(byMrt);
    }
  }, [byMrt]);

  // Ensure prev & next slide buttons are disabled correctly when data fetched
  useEffect(() => {
    if (body1Data && body1Data.files) {
      const container = scrollContainerRef.current;
      if (container) {
        container.addEventListener("scroll", updateSlideButtons);
        window.addEventListener("resize", updateSlideButtons);
        return () => {
          container.removeEventListener("scroll", updateSlideButtons);
          window.removeEventListener("resize", updateSlideButtons);
        };
      }
    }
  }, [body1Data]);

  const updateSlideButtons = () => {
    const container = scrollContainerRef.current;
    if (container) {
      const maxScroll = container.scrollWidth - container.clientWidth;
      setPrevSlideEnabled(container.scrollLeft > 0);
      setNextSlideEnabled(container.scrollLeft < maxScroll);
    }
  };

  const onPrevSlide = () => {
    const container = scrollContainerRef.current;
    if (container) {
      const newScrollLeft = Math.max(0, container.scrollLeft - scrollByNumber);
      container.scrollTo({
        left: newScrollLeft,
        behavior: "smooth",
      });
    }
  };

  const onNextSlide = () => {
    const container = scrollContainerRef.current;
    if (container) {
      const maxScroll = container.scrollWidth - container.clientWidth;
      const newScrollLeft = Math.min(
        maxScroll,
        container.scrollLeft + scrollByNumber
      );
      container.scrollTo({
        left: newScrollLeft,
        behavior: "smooth",
      });
    }
  };

  return (
    <Layout
      headerUrl={headerData && getInformationAsset(headerData)}
      title={"About Us"}
      headerHeight={"h-[228px] md:h-[416px]"}
    >
      {/* Subheader */}
      <div
        className="flex px-[28px] py-[58px] lg:px-[278px] lg:py-[80px] justify-center items-center font-[Oplicio] font-bold lg:text-2xl text-[#1F284E] text-center"
        style={{
          background: `url(${bgSubHeader})`,
          backgroundSize: "cover",
        }}
      >
        {subHeaderData
          ? extractTextFromTags(subHeaderData, "h4")
          : `orchardgateway is the one-and-only shopping mall that straddles both sides of Orchard Road and is linked by a glass tubular bridge and an underpass - forming a "gateway" to the bustling shopping belt`}
      </div>

      {/* Section 1 */}
      <div
        className="flex flex-col lg:flex-row gap-4 lg:gap-8 px-5 lg:px-20 py-[60px] lg:py-12"
        style={{ background: `url(${bgSection1})` }}
      >
        <div className="flex flex-row flex-1 gap-4 items-stretch lg:items-center order-2 lg:order-1">
          <div className="flex flex-1 basis-3/4 justify-end">
            <ImageLoader
              src={getInformationAsset(body2Data?.files[0]?.logo)}
              alt="Orchard Gateway"
              className="size-full max-w-[300px] lg:max-w-full aspect-[375/532] object-cover rounded-2xl"
            />
          </div>
          <div className="flex flex-1 basis-1/2 flex-col gap-4">
            <ImageLoader
              src={getInformationAsset(body2Data?.files[1]?.logo)}
              alt="Orchard Gateway"
              className="size-full max-w-[150px] lg:max-w-full aspect-[260/320] object-cover rounded-2xl"
            />
            <ImageLoader
              src={getInformationAsset(body2Data?.files[2]?.logo)}
              alt="Orchard Gateway"
              className="size-full max-w-[150px] lg:max-w-full aspect-[260/320] object-cover rounded-2xl"
            />
          </div>
        </div>
        <div className="flex flex-col flex-1 gap-3 lg:gap-4 justify-center order-1 lg:order-2">
          <div className="font-bold text-center lg:text-left text-xl md:text-2xl lg:text-4xl lg:leading-[60px] text-[#1F284E]">
            {body2Data?.description
              ? extractTextFromTags(body2Data.description, "h2")
              : "A Seamless Shopping Experience"}
          </div>
          <div className="text-sm text-center lg:text-left lg:text-xl text-[#1F284E]">
            {body2Data?.description ? (
              <HtmlView
                htmlContent={extractTextFromTags(body2Data.description, "p")}
              />
            ) : (
              "orchardgateway enjoys direct access to the Somerset MRT station via two basement floors and is also connected to the adjacent 313@Somerset and Orchard Central via the street-level Discovery Walk as well as inter-mall doorways on various floors. There is also a glass overhead bridge and underpass to enable weather-proof access across Orchard Road. Indeed, orchardgateway providess a seamless shopping experience within the Somerset shopping precinct."
            )}
          </div>
        </div>
      </div>

      {/* Section 2 */}
      <div className="flex flex-col bg-[#EDA542] items-center">
        <div className="font-bold text-xll/s md:text-2xl lg:text-4xl text-white mt-12 lg:mt-16 text-center">
          {body1Data?.description
            ? extractTextFromTags(body1Data.description, "h2")
            : "A Photo Moment at Every Corner"}
        </div>
        <div className="text-white text-sm md:text-lg lg:text-xl mx-4 lg:mt-4 text-center">
          {body1Data?.description
            ? extractTextFromTags(body1Data.description, "h4")
            : "Every corner you turn presents a photo opportunity. Enjoy Instagram moments of the mall's"}
        </div>
        <div className="flex flex-col lg:flex-row items-center w-full lg:mt-8 mb-8">
          <div className="flex-1 text-white font-[600] text-xs md:text-base lg:text-xl m-8 lg:ms-[72px]">
            {body1Data?.description ? (
              extractTextFromTags(body1Data.description, "ul")
                .split("\n")
                .map((line, index) => (
                  <p key={index}>
                    {line}
                    <br />
                    <br />
                  </p>
                ))
            ) : (
              <p>
                • Glass overhead bridge aka the tube, Orchard Road Viewing
                Gallery offering awe-inspiring postcard views of Orchard Road.
                Enjoy its dramatic display of LED lights.
                <br />
                <br />• Outdoor terrace aka the space, Somerset Viewing Deck
                offering a panoramic view of Somerset Road. <br />
                <br />• lighted balustrade with changing thematic display
                throughout the year.
              </p>
            )}
          </div>
          <div className="flex-1 basis-1/4 min-w-0">
            <SlidersAboutUs
              scrollContainerRef={scrollContainerRef}
              imagesUrl={body1Data?.files}
            />
          </div>
        </div>
        <div className="flex flex-row place-self-end cursor-pointer">
          <div
            className={`p-2 lg:p-4 ${
              prevSlideEnabled ? "bg-[#C78117]" : "bg-[#0000000F]"
            }`}
            onClick={onPrevSlide}
          >
            <ArrowLeftSlider className="h-6 lg:h-10 w-auto" />
          </div>
          <div
            className={`p-2 lg:p-4 ${
              nextSlideEnabled ? "bg-[#C78117]" : "bg-[#0000000F]"
            }`}
            onClick={onNextSlide}
          >
            <ArrowRightSlider className="h-6 lg:h-10 w-auto" />
          </div>
        </div>
      </div>

      {/* Section 3 */}
      <div className="flex flex-col lg:flex-row">
        <div className="flex flex-1 basis-[40%] overflow-hidden justify-center">
          <ImageLoader
            src={
              selectedGettingHereAssetMobile
                ? getInformationAsset(selectedGettingHereAssetMobile)
                : selectedGettingHereAsset &&
                  getInformationAsset(selectedGettingHereAsset)
            }
            className={`lg:hidden max-h-[450px] size-full object-contain`}
          />
          <ImageLoader
            src={
              selectedGettingHereAsset &&
              getInformationAsset(selectedGettingHereAsset)
            }
            className={`hidden lg:block size-full object-contain object-top`}
          />
        </div>

        <div
          id="direction"
          className="flex flex-1 basis-[60%] flex-col px-4 py-6 lg:px-[80px] lg:py-[80px]"
        >
          <div className="text-center lg:text-left text-lg md:text-2xl lg:text-4xl font-bold text-[#1F284E] mb-2 lg:mb-4">
            Getting Here
          </div>
          <div className="text-sm md:text-base lg:text-lg text-center lg:text-left text-[#1F284E]">
            Here's everything you need to know about how to navigate around
            orchardgateway
          </div>

          {selectedGettingHere?.name === "By MRT" ? (
            <div className="shadow-xl rounded-xl border-[#F4F4F4] border-[2px] p-4 lg:p-8 mt-3 lg:mt-6">
              <div className="flex flex-col">
                <div className="flex flex-row items-center text-[#50A85A] gap-3 font-bold text-sm md:text-lg lg:text-xl">
                  <MRTActive className="size-6 md:size-8 lg:size-10" />
                  By MRT
                </div>
                <div className="mt-2 lg:mt-4 lg:gap-2 ps-9 md:ps-[44px] lg:ps-[52px] flex flex-col text-xs md:text-sm lg:text-lg text-[#1F284E]">
                  <HtmlView htmlContent={byMrt?.description} />
                </div>
              </div>
            </div>
          ) : (
            <div
              onClick={() => setSelectedGettingHere(byMrt)}
              className="flex flex-row items-center text-[#1F284E] font-bold text-sm md:text-lg lg:text-xl gap-3 p-4 mt-3 lg:p-8 border-b-2 border-[#F2F2F2] cursor-pointer"
            >
              <MRT className="size-6 md:size-8 lg:size-10" />
              By MRT
            </div>
          )}

          {selectedGettingHere?.name === "By Bus" ? (
            <div className="shadow-xl rounded-xl border-[#F4F4F4] border-[2px] p-4 lg:p-8">
              <div className="flex flex-col">
                <div className="flex flex-row items-center text-[#50A85A] gap-3 font-bold text-sm md:text-lg lg:text-xl">
                  <BusActive className="size-6 md:size-8 lg:size-10" />
                  By Bus
                </div>
                <div className="mt-2 lg:mt-4 lg:gap-2 ps-9 md:ps-[44px] lg:ps-[52px] flex flex-col text-xs md:text-sm lg:text-lg text-[#1F284E]">
                  <HtmlView htmlContent={byBus?.description} />
                </div>
              </div>
            </div>
          ) : (
            <div
              onClick={() => setSelectedGettingHere(byBus)}
              className="flex flex-row items-center text-[#1F284E] font-bold text-sm md:text-lg lg:text-xl gap-3 p-4 mt-3 lg:p-8 border-b-2 border-[#F2F2F2] cursor-pointer"
            >
              <Bus className="size-6 md:size-8 lg:size-10" />
              By Bus
            </div>
          )}

          {selectedGettingHere?.name === "By Car" ? (
            <div className="shadow-xl rounded-xl border-[#F4F4F4] border-[2px] p-4 lg:p-8">
              <div className="flex flex-col">
                <div className="flex flex-row items-center text-[#50A85A] gap-3 font-bold text-sm md:text-lg lg:text-xl">
                  <CarActive className="size-6 md:size-8 lg:size-10" />
                  By Car
                </div>
                <div className="mt-2 lg:mt-4 lg:gap-2 ps-9 md:ps-[44px] lg:ps-[52px] flex flex-col text-xs md:text-sm lg:text-lg text-[#1F284E]">
                  <HtmlView htmlContent={byCar?.description} />
                </div>
              </div>
            </div>
          ) : (
            <div
              onClick={() => setSelectedGettingHere(byCar)}
              className="flex flex-row items-center text-[#1F284E] font-bold text-sm md:text-lg lg:text-xl gap-3 p-4 mt-3 lg:p-8 border-b-2 border-[#F2F2F2] cursor-pointer"
            >
              <Car className="size-6 md:size-8 lg:size-10" />
              By Car
            </div>
          )}

          {selectedGettingHere?.name?.includes("Carpark Rates") ? (
            <div className="shadow-xl rounded-xl border-[#F4F4F4] border-[2px] p-4 lg:p-8">
              <div className="flex flex-col">
                <div className="flex flex-row items-center text-[#50A85A] gap-3 font-bold text-sm md:text-lg lg:text-xl">
                  <ParkingActive className="size-6 md:size-8 lg:size-10" />
                  Carpark Rates
                </div>
              </div>
            </div>
          ) : (
            <div
              onClick={() => setSelectedGettingHere(carparkRates)}
              className="flex flex-row items-center text-[#1F284E] font-bold text-sm md:text-lg lg:text-xl gap-3 p-4 mt-3 lg:p-8 border-b-2 border-[#F2F2F2] cursor-pointer"
            >
              <Parking className="size-6 md:size-8 lg:size-10" />
              Carpark Rates
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
});

export default AboutUs;
