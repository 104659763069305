import React, { Suspense, useState, useEffect, Fragment } from "react";
import _ from "lodash";
import {
  AdsignMap,
  getPathBetwenShapes,
  getPathToShape,
  MapStore,
} from "@adactiveasia/adsign-map";
import { observer } from "mobx-react-lite";
import { mapConfig, mapOption } from "../../constants/mapConstant";
import Layout from "../../components/Layout";

import refreshIcon from "../../assets/icons/refresh.svg";
import switchImage from "../../assets/icons/switch.svg";
import arrowLeft from "../../assets/icons/SideBar-left.svg";
import arrowRight from "../../assets/icons/SideBar-right.svg";
import LoadingMapComponent from "../../components/LoadingMapComponent";
import { getInformationAsset, getPoiAsset } from "../../helper";
import SearchView from "./SearchView";
import DetailPoi from "../../components/DetailPoi";
import XIcon from "../../assets/icons/detail-poi-close.svg";
import Tour from "reactour";
import { logAnalyticsEvent } from "../../services/firebaseAnalytics";

const Floorguide = observer(() => {
  const mapStore = React.useRef(new MapStore()).current;

  const sceneRef = React.useRef(null);
  const cameraControlsRef = React.useRef(null);
  const interactiveRef = React.useRef(null);
  const [selectedAmenities, setSelectedAmenities] = useState({});
  const [floorGuidePageImg, setFloorGuidePageImg] = useState(null);
  const [isShowSearchView, setIsShowSearchView] = useState(false);
  const [config, setConfig] = useState([]);
  const [startPoint, setStartPoint] = useState(null);
  const [endPoint, setEndPoint] = useState(null);
  const [searchPoiTextField, setSearchPoiTextField] = useState(null);
  const [isFillStartPoint, setIsFillStartPoint] = useState(null);
  const [isShowModalToWayfinding, setIsShowModalToWayfinding] = useState(false);
  const [listAmenities, setListAmenities] = useState([]);
  const [selectedPoi, setSelectedPoi] = useState([]);
  const [newPois, setNewPois] = useState([]);
  const [directionIndex, setDirectionIndex] = useState(0);
  const [wayfindingMethod, setWayfindingMethod] = useState(null);
  const [isSwitchClicked, setIsSwitchClicked] = useState(false);
  const [isMinimized, setIsMinimized] = useState(false);
  const startPointInputRef = React.useRef(null);
  const endPointInputRef = React.useRef(null);
  const [isTourOpen, setIsTourOpen] = useState(false);
  const [startIndexTour, setStartIndexTour] = useState(0);
  const tourSteps = [
    {
      selector: '[data-tut="reactour__iso"]',
      // content: `Please fill your current location to start routing maps.`,
      content: () => (
        <div className="flex flex-col gap-2 w-full">
          <div className="flex flex-row gap-2">
            <img
              src={require("../../assets/info-circle.png")}
              className="w-4 h-4"
            />
            <span className="text-sm font-medium">Ready to find your way?</span>
          </div>
          <div className="w-full text-xs">
            Enter your current location to get directions
          </div>
        </div>
      ),
      position: "top",
    },
    {
      selector: '[data-tut="to"]',
      // content: `Please fill your current location to start routing maps.`,
      content: () => (
        <div className="flex flex-col gap-2 w-full">
          <div className="flex flex-row gap-2">
            <img
              src={require("../../assets/info-circle.png")}
              className="w-4 h-4"
            />
            <span className="text-sm font-medium">Set Your Destination</span>
          </div>
          <div className="w-full text-xs">
            Tell us where you're going, and we'll show you the way.
          </div>
        </div>
      ),
      position: "bottom",
    },
  ];

  useEffect(() => {
    logAnalyticsEvent("destination", {
      page_title: `floorguide`,
    });
    fetch("/config.json")
      .then((response) => response.json())
      .then((data) => setConfig(data))
      .catch((error) => console.error("Error loading config.json:", error));
  }, []);

  useEffect(() => {
    focusDirection();
  }, [mapStore.directions]);

  const handleExitWayfinding = () => {
    // reset textfield
    const currentFloorId = mapStore.activeFloorId;
    setSelectedPoi(null);
    setStartPoint(null);
    setDirectionIndex(0);
    startPointInputRef.current.value = null;
    setEndPoint(null);
    endPointInputRef.current.value = null;

    interactiveRef.current.resetMap();
    setStartShape(null);
    setEndShape(null);
    interactiveRef.current.changeActiveFloor(currentFloorId);
  };
  useEffect(() => {
    if (mapStore && mapStore.information && mapStore.information.length > 0) {
      const directoryData = mapStore.information.filter((info) => {
        return info.name.toLowerCase().includes("floor guide page");
      });
      const landingPageData = directoryData.filter((data) => {
        return data.name.toLowerCase().includes("header - image");
      });

      if (landingPageData && landingPageData.length > 0) {
        setFloorGuidePageImg(landingPageData[0].files[0].logo);
      }
    }
  }, [mapStore.information]);

  useEffect(() => {
    const filteredData = mapStore?.pois?.filter(
      (item) => item.categoryId === "67a308d7e08f855b75e00611"
    );
    filteredData.sort((a, b) => a.name.localeCompare(b.name));
    setListAmenities(filteredData);

    if (mapStore?.pois && newPois.length < 1) {
      const amenitiesPoi = mapStore.pois.filter((poi) => {
        return poi.categoryName === "Amenities";
      });
      const initialPois = mapStore.pois;
      amenitiesPoi?.map((amenities) => {
        const listAmenities = amenities?.location?.split(", ");
        listAmenities?.forEach((element) => {
          initialPois.push({
            name: amenities.name,
            location: element,
            categoryId: amenities.categoryId,
            categoryIds: amenities.categoryIds,
            categoryName: amenities.categoryName,
            description: amenities.description,
            firstMedias: amenities.firstMedias,
            floor: amenities.floor,
            logo: amenities.logo,
            modifiedAt: amenities.modifiedAt,
            modifiedBy: amenities.modifiedBy,
            opentime: amenities.opentime,
            phone: amenities.phone,
            priority: amenities.priority,
            secondMedias: amenities.secondMedias,
            siteId: amenities.siteId,
            subCategoryIds: amenities.subCategoryIds,
            tags: amenities.tags,
            url: amenities.url,
            __v: amenities.__v,
            _id: amenities._id,
          });
        });
      });

      setNewPois(initialPois);
    }
  }, [mapStore.pois]);

  useEffect(() => {
    if (mapStore.startShape && mapStore.destinationShape && !isSwitchClicked) {
      setIsShowSearchView(false);
      setIsShowModalToWayfinding(true);
    }

    setIsSwitchClicked(false);
  }, [mapStore.startShape, mapStore.destinationShape]);

  useEffect(() => {
    if (mapStore.startPOI && !mapStore.destinationPOI) {
      setIsTourOpen(true);
      setStartIndexTour(1);
    } else if (!mapStore.startPOI && mapStore.destinationPOI) {
      setIsTourOpen(true);
      setStartIndexTour(0);
    } else {
      setIsTourOpen(false);
    }
  }, [mapStore.startPOI, mapStore.destinationPOI]);

  const handleClickAmenities = (amenity) => {
    mapStore.freeShapes.forEach((e) => {
      console.log(e, "277");
      interactiveRef.current.changeTopImage(e._id, null);
    });
    if (selectedAmenities.name === amenity.name) {
      mapStore.freeShapes.forEach((e) => {
        console.log(e, "277");
        interactiveRef.current.changeTopImage(e._id, null);
      });
      setSelectedAmenities({});
      mapStore.setCurrentFreeShape([]);
      return;
    }

    setSelectedAmenities(amenity);
    mapStore.setCurrentFreeShape([]);

    const findAmenity = listAmenities.find(
      (item) => item.name === amenity.name
    );
    const locations = findAmenity.location.split(",");

    const shape = mapStore.freeShapes.filter((data) =>
      locations.some(
        (loc) => loc.trim() === data.name || loc.trim() === `#${data.name}`
      )
    );

    if (selectedAmenities === amenity.name) {
      mapStore.currentFreeShape.forEach((e) => {
        console.log(`/poi/${findAmenity.logo}`, "123");
        interactiveRef.current.changeTopImage(
          e._id,
          `/poi/${findAmenity.logo}`
        );
      });

      mapStore.setCurrentFreeShape([]);
    } else {
      mapStore.currentFreeShape.forEach((e) => {
        listAmenities.forEach((item) => {
          const locationsData = item.location.split(",");

          if (
            locationsData.some(
              (loc) => loc.trim() === e.name || loc.trim() === `#${e.name}`
            )
          ) {
            console.log(`/poi/${item.logo}`, "323");
            interactiveRef.current.changeTopImage(e._id, `/poi/${item.logo}`);
          }
        });
      });

      mapStore.setCurrentFreeShape(shape);

      if (mapStore.currentFreeShape.length > 0) {
        const matchedFloor = mapStore.currentFreeShape.find(
          (e) => e.floorId === mapStore.activeFloorId
        );

        if (!matchedFloor) {
          const currentIndex = mapStore.floors.findIndex(
            (floor) => floor._id === mapStore.activeFloorId
          );

          const targetFloors = mapStore.currentFreeShape.filter(
            (e) => e.floorId !== mapStore.activeFloorId
          );

          const targetsWithIndex = targetFloors
            .map((target) => ({
              ...target,
              index: mapStore.floors.findIndex(
                (floor) => floor._id === target.floorId
              ),
            }))
            .filter((target) => target.index !== -1);

          const nearestFloor = targetsWithIndex.reduce(
            (closest, target) => {
              const distance = Math.abs(target.index - currentIndex);

              return distance < closest.distance
                ? {
                    floorId: target.floorId,
                    index: target.index,
                    distance,
                  }
                : closest;
            },
            { floorId: null, index: -1, distance: Infinity }
          );

          mapStore.setActiveFloorId(nearestFloor.floorId);
          interactiveRef.current.changeActiveFloor(nearestFloor.floorId);
        }
      }

      const activeImageAmenity =
        findAmenity.firstMedias && findAmenity.firstMedias.length > 0
          ? findAmenity.firstMedias[0]
          : "";

      mapStore.currentFreeShape.forEach((e) => {
        interactiveRef.current.changeTopImage(
          e._id,
          getPoiAsset(activeImageAmenity)
        );
      });

      if (selectedAmenities === amenity.name) {
        mapStore.setCurrentFreeShape([]);
        interactiveRef.current.changeActiveFloor(mapStore.activeFloorId);
      } else {
        mapStore.setCurrentFreeShape(shape);
      }
    }
  };

  const changeFloor = (floor) => {
    interactiveRef.current.changeActiveFloor(floor._id);
    mapStore.setActiveFloorId(floor._id);
  };

  const scrollContainerRef = React.useRef(null);

  const scrollLeft = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({
        left: -20,
        behavior: "smooth",
      });
    }
  };

  const scrollRight = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({
        left: 20,
        behavior: "smooth",
      });
    }
  };

  const changeMapDimesion = () => {
    mapStore.currentDimension === "3d"
      ? mapStore.setCurrentDimension("2d")
      : mapStore.setCurrentDimension("3d");
  };

  const expandMap = () => {
    mapStore.setExpanded(!mapStore.isExpanded);
  };

  const handleClose = () => {
    // mainStore.setSelectedPOI(null);
    mapStore.setCurrentFreeShape([]);
    interactiveRef.current.resetMap();
    setStartShape(null);
    setEndShape(null);
    setIsShowModalToWayfinding(false);
  };

  const startWayfinding = async (method) => {
    try {
      // setWayfindingMethod(method);
      // mapStore.setStartPOI(startPoint);
      // mapStore.setDestinationPOI(endPoint);

      // let shapeTo = mapStore.freeShapes.find((data) => {
      //   if (endPoint?.location && endPoint?.location.split(",")) {
      //     return endPoint?.location.split(",").includes(data.name);
      //   }
      //   return (
      //     endPoint?.location === data.name ||
      //     endPoint?.location === "#" + data.name
      //   );
      // });

      // if (startPoint?.name) {
      //   const shapeFrom = mapStore.freeShapes.find((data) => {
      //     if (startPoint?.location && startPoint?.location.split(",")) {
      //       return startPoint?.location.split(",").includes(data.name);
      //     }
      //     return (
      //       startPoint?.location === data.name ||
      //       startPoint?.location === "#" + data.name
      //     );
      //   });

      //   mapStore.setExpanded(true);
      //   // interactiveRef.current.changeColorFreeShapes([shapeFrom?._id], "#E09948");
      //   // interactiveRef.current.changeColorFreeShapes([shapeTo?._id], "#4999A3");
      //   // mapStore.setActiveFloorId(shapeTo.floorId);
      //   if (mapStore.directions.length > 0) {
      //     setTimeout(() => {
      //       interactiveRef.current.cameraFocusOnDirection(
      //         mapStore.directions[0]
      //       );
      //     }, 1000);
      //   }
      //   const path = getPathBetwenShapes(mapStore, shapeFrom, shapeTo, method);
      //   return;
      // }
      mapStore.setExpanded(true);
      console.log("shape", mapStore.startShape, mapStore.destinationShape);
      const path = getPathBetwenShapes(
        mapStore,
        mapStore.startShape,
        mapStore.destinationShape,
        method
      );

      // mapStore.setActiveFloorId(shapeTo.floorId);
      if (mapStore.directions.length > 0) {
        setTimeout(() => {
          interactiveRef.current.cameraFocusOnDirection(mapStore.directions[0]);
        }, 1000);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const focusDirection = () => {
    if (mapStore.directions.length > 0) {
      setTimeout(() => {
        interactiveRef.current.cameraFocusOnDirection(mapStore.directions[0]);
      }, 1000);
    }
    // interactiveRef.current.cameraFocusOnDirection(mapStore.directions[0]);
  };

  const renderAmenitiesImage = (name) => {
    switch (name) {
      case "ATM":
        return "ATM";
      case "Drop Off":
        return "Drop-Off";
      case "Escalators":
        return "Escalator";
      case "Lifts":
        return "Lift";
      case "MRT":
        return "MRT";
      case "Nursing Room":
        return "Nursing";
      case "Restroom":
        return "Toilet";
      case "Concierge":
        return "concierge";
      case "Free Wifi":
        return "wifi";
      default:
        return "ATM";
    }
  };

  const [startShape, setStartShape] = useState(null);
  const [endShape, setEndShape] = useState(null);

  const handleSwitch = () => {
    const tempStartPOI = mapStore.startPOI;
    const tempStartShape = mapStore.startShape;

    mapStore.setStartPOI(mapStore.destinationPOI);
    mapStore.setStartShape(mapStore.destinationShape);

    mapStore.setDestinationPOI(tempStartPOI);
    mapStore.setDestinationShape(tempStartShape);
    // setStartPoint(endPoint);
    // setEndPoint(tempStart);
    startWayfinding(wayfindingMethod);
  };

  const isShapeInPoiLocation = (poiLocation, objectName) => {
    let result = false;

    if (objectName) {
      if (poiLocation.includes(",")) {
        const poiLocations = poiLocation.split(",");
        poiLocations.forEach((poi) => {
          if (objectName.trim() === poi.trim()) result = true;
        });
      } else if (objectName.trim() === poiLocation.trim()) {
        result = true;
      }
    }

    return result;
  };

  const onShapeClick = (e) => {
    if (e.props.labels && e.props.labels.length > 0) {
      let props = e.props.labels[0];
      if (props.type === "poi") {
        const poi =
          mapStore.pois &&
          mapStore.pois.find(
            ({ _id, location }) =>
              _id === props.poiId &&
              (e.props.pois
                ? e.props.pois.includes(_id)
                : isShapeInPoiLocation(location, e.name))
          );
        if (poi) {
          if (!mapStore.destinationShape) {
            mapStore.setDestinationPOI(poi);
            mapStore.setDestinationShape(e);
            logAnalyticsEvent("destination", {
              poiDestination: poi.name,
            });
          } else if (mapStore.destinationShape && mapStore.startShape) {
            mapStore.setDestinationPOI(poi);
            mapStore.setDestinationShape(e);
            logAnalyticsEvent("destination", {
              poiDestination: poi.name,
            });
          } else {
            mapStore.setStartPOI(poi);
            mapStore.setStartShape(e);
            logAnalyticsEvent("destination", {
              poiStart: poi.name,
            });
          }
          interactiveRef.current.focusOnShape(e);
        }
      }
    }
  };

  const handleStartEndPoint = (poi, type) => {
    const freeShape = mapStore.freeShapes.find((data) => {
      if (poi.location && poi.location.split(",")) {
        return poi.location.split(",").includes(data.name);
      }
      return poi.location === data.name || poi.location === "#" + data.name;
    });
    if (freeShape) {
      if (type == "start") {
        mapStore.setStartShape(freeShape);
        mapStore.setStartPOI(poi);
      } else {
        mapStore.setDestinationShape(freeShape);
        mapStore.setDestinationPOI(poi);
      }
      interactiveRef.current.changeActiveFloor(freeShape.floorId);
      setTimeout(() => {
        interactiveRef.current.focusOnShape(freeShape);
      }, 500);
    }
  };

  const locationSearchBar = () => {
    return (
      <div className="absolute top-2 left-1/2 transform -translate-x-1/2 md:top-2 xl:top-14 md:right-[30%] bg-white rounded-xl shadow-lg shadow-black/10 flex flex-row px-2 py-2 gap-2 w-full max-w-[90%] md:max-w-[640px]">
        <div className="flex flex-col items-center justify-center">
          <div className="w-2.5 h-2.5 bg-[#E09948] border border-white/40 rounded-full mb-1" />

          <div className="h-9 border-l-2 border-dashed border-gray-300" />

          <div className="w-2.5 h-2.5 bg-[#4999A3] rounded-full border border-white/40 mt-1" />
        </div>
        <div className="flex-grow flex flex-col justify-around gap-2 w-full">
          <div
            data-tut="reactour__iso"
            className="flex flex-row w-full items-center justify-center bg-[#0000000A] p-2 rounded-lg"
          >
            <input
              className="flex flex-row w-full items-center justify-center bg-transparent rounded-lg"
              placeholder="Your Location"
              value={mapStore.startPOI?.name}
              ref={startPointInputRef}
              onFocus={() => {
                setIsShowSearchView(true);
                setSearchPoiTextField(null);
                setIsFillStartPoint(true);
                setIsTourOpen(false);
              }}
              onChange={(e) => setSearchPoiTextField(e.target.value)}
            />
            {mapStore.startPOI?.name && (
              <img
                src={XIcon}
                className="h-6 w-6"
                onClick={() => {
                  const currentFloorId = mapStore.activeFloorId;
                  startPointInputRef.current.value = null;
                  mapStore.setStartPOI(null);
                  mapStore.setStartShape(null);

                  if (mapStore?.directions?.length > 0) {
                    mapStore.setDestinationPOI(null);
                    mapStore.setDestinationShape(null);
                    endPointInputRef.current.value = null;
                    interactiveRef.current.resetMap();
                  }
                  interactiveRef.current.changeActiveFloor(currentFloorId);
                }}
              />
            )}
          </div>

          <div
            data-tut="to"
            className="flex flex-row w-full items-center justify-center bg-[#0000000A] p-2 rounded-lg"
          >
            <input
              className="flex flex-row w-full items-center justify-center bg-transparent rounded-lg"
              placeholder="Where to?"
              value={mapStore.destinationPOI?.name}
              ref={endPointInputRef}
              onFocus={() => {
                setIsShowSearchView(true);
                setSearchPoiTextField(null);
                setIsFillStartPoint(false);
                setIsTourOpen(false);
              }}
              onChange={(e) => setSearchPoiTextField(e.target.value)}
            />
            {mapStore.destinationPOI?.name && (
              <img
                src={XIcon}
                className="h-6 w-6"
                onClick={() => {
                  const currentFloorId = mapStore.activeFloorId;

                  mapStore.setDestinationPOI(null);
                  mapStore.setDestinationShape(null);
                  endPointInputRef.current.value = null;
                  if (mapStore?.directions?.length > 0) {
                    startPointInputRef.current.value = null;
                    mapStore.setStartPOI(null);
                    mapStore.setStartShape(null);
                    interactiveRef.current.resetMap();
                  }
                  interactiveRef.current.changeActiveFloor(currentFloorId);
                }}
              />
            )}
          </div>
        </div>

        <div className="place-content-center px-2">
          <img src={switchImage} onClick={handleSwitch} />
        </div>
      </div>
    );
  };

  return (
    <Layout
      title={"Floor Guide"}
      headerUrl={floorGuidePageImg && getInformationAsset(floorGuidePageImg)}
      headerHeight={"h-[228px] md:h-[416px]"}
    >
      <div className="h-full">
        <div className="h-[600px] md:h-[800px] relative">
          {mapStore?.directions?.length > 0 && (
            <div
              className={`absolute z-[40] right-4 bottom-[-10px] md:bottom-32  ${
                isMinimized ? "h-18" : "h-[300px] md:h-[350px]"
              }`}
            >
              <div
                className={`w-[260px] md:w-[340px] rounded-xl transition-all duration-300 ${
                  isMinimized ? "h-18" : "h-[260px] md:h-[310px]"
                }`}
              >
                {/* Direction Box */}
                <div className="rounded-lg md:rounded-xl mb-2 bg-white backdrop-blur-xl border-4 border-black/5 overflow-y-scroll h-full">
                  <div className="text-white p-2 md:p-4 rounded-lg">
                    {/* Header Section with Toggle Button */}
                    <div className="flex justify-between items-center rounded-md p-2 md:p-3 bg-[#546AC5]">
                      <span className="text-white text-md md:text-base font-semibold">
                        DIRECTION
                      </span>
                      <button
                        className="text-white text-xl focus:outline-none"
                        onClick={() => setIsMinimized(!isMinimized)}
                      >
                        {isMinimized ? "🔼" : "🔽"}
                      </button>
                    </div>

                    {/* Direction Steps (Hidden when Minimized) */}
                    {!isMinimized && (
                      <div className="max-w-[260px] md:w-[340px] px-1 md:px-3">
                        <div className="mt-4 space-y-2 text-xs md:text-sm">
                          {mapStore.directions.map((direction, index) => (
                            <Fragment key={index}>
                              <div
                                onClick={() => {
                                  try {
                                    setDirectionIndex(index);
                                    interactiveRef.current.cameraFocusOnDirection(
                                      direction
                                    );
                                  } catch (e) {}
                                }}
                                className={`flex gap-1 md:gap-2 min-h-[30px] text-[#525252] cursor-pointer ${
                                  directionIndex === index
                                    ? "font-semibold"
                                    : "font-normal"
                                }`}
                              >
                                <span className="min-w-[50px] md:min-w-[60px]">
                                  Step {index + 1} :
                                </span>
                                <span className="max-w-[210px]">
                                  {direction.instruction.replace(
                                    /<\/?[^>]+(>|$)/g,
                                    ""
                                  )}
                                </span>
                              </div>
                            </Fragment>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                {/* Exit Button (Hidden when Minimized) */}
                {!isMinimized && (
                  <button
                    onClick={handleExitWayfinding}
                    className="flex h-[40px] w-[260px] md:w-[340px] items-center border-4 bg-white border-white rounded-lg overflow-hidden backdrop-blur-xl border-4 border-black/5"
                  >
                    <div className="flex flex-row w-full h-[40px] items-center rounded-xl mx-[6px] bg-[#ED8080]">
                      <div>
                        <svg
                          width="40"
                          height="40"
                          viewBox="0 0 40 40"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            width="40"
                            height="40"
                            fill="black"
                            fillOpacity="0.06"
                          />
                          <rect
                            x="25.866"
                            y="12.667"
                            width="1.33333"
                            height="18.6667"
                            transform="rotate(45 25.866 12.667)"
                            fill="white"
                          />
                          <rect
                            width="1.33333"
                            height="18.6667"
                            transform="matrix(-0.707107 0.707107 0.707107 0.707107 13.6095 12.667)"
                            fill="white"
                          />
                        </svg>
                      </div>
                      <div className="text-center font-semibold text-md md:text-base text-white ml-6 md:ml-16">
                        END NAVIGATION
                      </div>
                    </div>
                  </button>
                )}
              </div>
            </div>
          )}
          <Suspense fallback={<LoadingMapComponent />}>
            <AdsignMap
              cameraControlsRef={cameraControlsRef}
              sceneRef={sceneRef}
              config={mapConfig}
              interactiveRef={interactiveRef}
              store={mapStore}
              onShapeClick={(e) => onShapeClick(e)}
              mapOption={mapOption}
            />
          </Suspense>
          {locationSearchBar()}
          {isShowSearchView && (
            <SearchView
              assetUrl={config.assetUrl}
              query={searchPoiTextField}
              onClose={() => setIsShowSearchView(false)}
              setStartPoint={(poi) => handleStartEndPoint(poi, "start")}
              setEndPoint={(poi) => handleStartEndPoint(poi, "end")}
              isFillStartPoint={isFillStartPoint}
              poisData={newPois}
            />
          )}
          <div className="block md:hidden absolute rounded rounded-md bg-[#F8F8F8] pt-1 pr-1 pl-1 left-2 top-[7.5rem] z-20 overflow-y-auto md:max-h-[calc(100%-10rem)] scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-100">
            {_.orderBy(
              mapStore.floors.map((floor) => {
                const x = floor;
                const splitedText = x.name.split("");
                const orderNumber =
                  splitedText[0] === "B"
                    ? parseInt(-splitedText[1])
                    : parseInt(splitedText[1]);
                return {
                  ...x,
                  order_number: orderNumber,
                };
              }),
              (level) => level.order_number,
              "order_number"
            )
              .reverse()
              .filter(
                (level) =>
                  mapStore.visibleFloors.length === 0 ||
                  mapStore.visibleFloors.includes(level._id)
              )
              .map((floor) => {
                return (
                  <div
                    className={`flex items-center justify-center p-2 mb-1 cursor-pointer ${
                      floor._id == mapStore.activeFloorId
                        ? "bg-[rgba(80,168,90,0.12)] text-[#50A85A] border border-[#50A85A]"
                        : "bg-[#ECECEC] text-[#8F8F8F] border border-white"
                    } rounded-md text-xs font-bold `}
                    onClick={() => {
                      changeFloor(floor);
                    }}
                  >
                    {floor.name.replace("Floor", "L")}
                  </div>
                );
              })}
          </div>
          <div className="hidden md:block absolute rounded rounded-lg bg-[#F8F8F8] left-2 top-32 xl:left-6 xl:top-14 z-20 p-2 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-100">
            <div className="flex flex-col gap-y-2 ">
              {_.orderBy(
                mapStore.floors.map((floor) => {
                  const x = floor;
                  const splitedText = x.name.split("");
                  const orderNumber =
                    splitedText[0] === "B"
                      ? parseInt(-splitedText[1])
                      : parseInt(splitedText[1]);
                  return {
                    ...x,
                    order_number: orderNumber,
                  };
                }),
                (level) => level.order_number,
                "order_number"
              )
                .reverse()
                .filter(
                  (level) =>
                    mapStore.visibleFloors.length === 0 ||
                    mapStore.visibleFloors.includes(level._id)
                )
                .map((floor) => {
                  return (
                    <div
                      className={`flex items-center justify-center px-4 xl:px-6 py-2 cursor-pointer ${
                        floor._id == mapStore.activeFloorId
                          ? "bg-[rgba(80,168,90,0.12)] text-[#50A85A] border border-[#50A85A]"
                          : "bg-[#ECECEC] text-[#8F8F8F] border border-white"
                      } rounded-md text-2xl md:text-[32px] font-bold`}
                      onClick={() => {
                        changeFloor(floor);
                      }}
                    >
                      {floor.name.replace("Floor", "L")}
                    </div>
                  );
                })}
            </div>
          </div>

          {/* <div
            className="absolute right-0 md:right-2 z-20 overflow-y-auto max-h-[calc(100%-6rem)] scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-100"
            style={{
              top: "10rem",
            }}
          >
            <div className="flex flex-row gap-x-2 mt-2">
              <div
                className="bg-[#ECECEC] p-3 rounded rounded-2 h-8 w-8 md:h-[46px] md:w-[46px]"
                onClick={() => expandMap()}
              >
                <img src={refreshIcon} />
              </div>
              <div
                className="flex flex-col bg-[#ECECEC] cursor-pointer md:p-3 rounded rounded-2 align-center justify-center items-center h-8 w-8 md:h-[46px] md:w-[46px]"
                onClick={() => changeMapDimesion()}
              >
                <span className="text-[#848484] font-bold text-xs md:text-2xl">
                  {mapStore.currentDimension}
                </span>
              </div>
            </div>
          </div> */}
          <div className="absolute w-full bottom-10 md:left-1/2 md:transform md:-translate-x-1/2 flex flex-row overflow-x-auto scrollbar-none justify-center items-center gap-x-2 md:gap-x-4 px-2 md:px-6 py-4">
            <button
              onClick={scrollLeft}
              className="w-4 h-8 md:h-16 md:w-10 shrink-0 focus:outline-none md"
            >
              <img
                src={arrowLeft}
                alt="Scroll Left"
                className="w-full h-full object-contain"
              />
            </button>

            <div
              ref={scrollContainerRef}
              className="flex flex-row md:w-[80%] space-x-2 xl:space-x-4 xl:space-x-6 overflow-x-scroll [&::-webkit-scrollbar]:hidden
            [-ms-overflow-style:none]
            [scrollbar-width:none]"
            >
              {listAmenities.map((amenities) => (
                <div
                  key={amenities.slug}
                  className={`${
                    selectedAmenities.name === amenities.name
                      ? "bg-[rgba(80,168,90,0.12)] text-[#50A85A] border border-[#50A85A]"
                      : "bg-[#F8F8F8] border border-transparent"
                  } w-14 md:w-32 flex-shrink-0 flex flex-col items-center justify-center px-2 md:px-4 py-2 gap-y-2 cursor-pointer rounded rounded-md`}
                  onClick={() => handleClickAmenities(amenities)}
                >
                  <img
                    src={require(`../../assets/icons/${renderAmenitiesImage(
                      amenities?.name
                    )}.svg`)}
                    className="h-6 w-6 md:h-10 md:w-10"
                    alt={amenities.name}
                  />
                  <span className="hidden md:block text-[#1F284E] text-[6px] md:text-xs font-semibold text-center line-clamp-2">
                    {amenities.name}
                  </span>
                  <div className="block md:hidden h-4">
                    <span className="text-[#1F284E] text-[6px] md:text-xs font-semibold text-center line-clamp-2">
                      {amenities.name}
                    </span>
                  </div>
                </div>
              ))}
            </div>

            <button
              onClick={scrollRight}
              className="w-4 h-8 md:h-16 md:w-10 shrink-0 focus:outline-none"
            >
              <img
                src={arrowRight}
                alt="Scroll Right"
                className="w-full h-full object-contain"
              />
            </button>
          </div>
        </div>

        {isShowModalToWayfinding && (
          <div className="absolute top-40 right-[30%]">
            <DetailPoi
              poi={mapStore.destinationPOI}
              poiFrom={mapStore.startPOI}
              startShape={mapStore.startShape}
              destinationShape={mapStore.destinationShape}
              onClose={() => {
                setIsShowModalToWayfinding(false);
              }}
              wayfindingByEscalator={() => startWayfinding("not-disabled")}
              wayfindingByLift={() => startWayfinding("only-disabled")}
              wayfindingByStandart={() => startWayfinding("standart")}
              mapStore={mapStore}
            />
          </div>
        )}

        <Tour
          steps={tourSteps}
          isOpen={isTourOpen}
          onRequestClose={() => setIsTourOpen(false)}
          closeWithMask={false}
          accentColor="#5cb7b7"
          showNavigationNumber={false}
          showNavigation={false}
          rounded={10}
          showButtons={false}
          showNumber={false}
          highlightedMaskClassName="lorem"
          startAt={startIndexTour}
          disableInteraction={false}
        />

        <div className="h-5 md:h-[40px]"></div>
      </div>
    </Layout>
  );
});

export default Floorguide;
