import React, { useEffect, useRef, useState } from "react";
import noEventIcon from "../../assets/no-event.svg";
import calendarIcon from "../../assets/icons/Calendar.svg";
import arrowRight from "../../assets/icons/arrow-right.svg";
import arrowRightSmall from "../../assets/icons/arrow-right-small.png";
import arrowLeftSmall from "../../assets/icons/arrow-left-small.png";
import ArrowRightSlider from "../../assets/icons/arrow-right-slider";
import ArrowLeftSlider from "../../assets/icons/arrow-left-slider";
import {
  extractTextFromTags,
  getInformationAsset,
  getMediaAsset,
  getPoiAsset,
} from "../../helper";
import DetailEvent from "../DetailEvent";
import ImageLoader from "../ImageLoader";
import TermsConditions from "../TermsConditions";

const ImageRowSliderPlaceholder = () => {
  const totalPlaceholder = 4;

  return (
    <>
      {Array.from({ length: totalPlaceholder }, (_, index) => (
        <div
          key={`placeholder-${index}`}
          className="flex-shrink-0 w-[270px] md:w-[660px] rounded-xl overflow-hidden relative"
        >
          <ImageLoader className="h-[210px] w-[270px] md:w-[660px] md:h-[500px]" />
        </div>
      ))}
    </>
  );
};

const ImageRowSlider = ({ datas, type, urlTnc }) => {
  const scrollContainerRef = useRef(null);
  const sliderButtonRef = useRef(null);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [scrollLeftEnabled, setScrollLeftEnabled] = useState(false);
  const [scrollRightEnabled, setScrollRightEnabled] = useState(false);
  const scrollByNumber = 200;
  const colorInActiveScroll = "#D5D5D5";
  const colorInActiveScrollMobile = "#E8E8E8";
  const colorActiveScroll = "#848484";
  const colorActiveScrollMobile = "#D7D7D7";

  const [openTnc, setOpenTnc] = useState(false);

  useEffect(() => {
    if (selectedEvent) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }

    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, [selectedEvent]);

  useEffect(() => {
    if (sliderButtonRef.current) {
      if (type === "whats-on") {
        sliderButtonRef.current.classList.add("mr-4");
        sliderButtonRef.current.classList.add("xl:mr-0");
        sliderButtonRef.current.classList.remove("mr-8");
      }
    }
  }, [datas, type]);

  // Adjust slider items justify position
  useEffect(() => {
    if (scrollRightEnabled) {
      scrollContainerRef.current.classList.remove("justify-center");
    } else {
      scrollContainerRef.current.classList.add("justify-center");
    }
  }, [scrollRightEnabled]);

  // Ensure slider buttons are disabled correctly
  useEffect(() => {
    updateSlideButtons();

    const container = scrollContainerRef.current;
    if (container) {
      container.addEventListener("scroll", updateSlideButtons);
      window.addEventListener("resize", updateSlideButtons);
      return () => {
        container.removeEventListener("scroll", updateSlideButtons);
        window.removeEventListener("resize", updateSlideButtons);
      };
    }
  }, [datas]);

  const updateSlideButtons = () => {
    const container = scrollContainerRef.current;
    if (container) {
      const maxScroll = container.scrollWidth - container.clientWidth;
      setScrollLeftEnabled(container.scrollLeft > 0);
      setScrollRightEnabled(container.scrollLeft < maxScroll);
    }
  };

  const scrollLeft = () => {
    const container = scrollContainerRef.current;
    if (container) {
      const newScrollLeft = Math.max(0, container.scrollLeft - scrollByNumber);
      container.scrollTo({
        left: newScrollLeft,
        behavior: "smooth",
      });
    }
  };

  const scrollRight = () => {
    const container = scrollContainerRef.current;
    if (container) {
      const maxScroll = container.scrollWidth - container.clientWidth;
      const newScrollLeft = Math.min(
        maxScroll,
        container.scrollLeft + scrollByNumber
      );
      container.scrollTo({
        left: newScrollLeft,
        behavior: "smooth",
      });
    }
  };

  return (
    <div className={`w-full`}>
      <div
        ref={scrollContainerRef}
        className={`flex overflow-x-auto scrollbar-hide scroll-smooth gap-4 py-4 px-4 md:px-8 xl:px-[120px]`}
        style={{
          scrollbarWidth: "none",
          msOverflowStyle: "none",
          touchAction: "auto", // Enable touch scrolling
          WebkitOverflowScrolling: "touch", // Improves scrolling performance on iOS
        }}
      >
        <style jsx>{`
          .scrollbar-hide::-webkit-scrollbar {
            display: none;
          }
        `}</style>

        {datas ? (
          datas.map((data, index) => {
            if (data.name !== "Amenities" && data.name !== "Tenants")
              return (
                <div
                  key={data?._id}
                  className="flex-shrink-0 w-[270px] md:w-[660px] rounded-xl overflow-hidden relative"
                >
                  <ImageLoader
                    src={
                      type === "directory"
                        ? getPoiAsset(data?.logo ?? "")
                        : getMediaAsset(data?.logo)
                    }
                    className="h-[210px] w-[270px] md:w-[660px] md:h-[500px]"
                  />
                  {type === "whats-on" && (
                    <div
                      className="absolute bottom-0 flex flex-col bg-gradient-to-b from-transparent to-black w-full h-[60%]"
                      onClick={() => setSelectedEvent(data)}
                    >
                      <div className="flex flex-col mt-auto">
                        <span className="text-white font-bold text-sm md:text-xl px-2 md:px-4">
                          {data?.name}
                        </span>
                        <div className="flex flex-row gap-x-2 p-2 md:p-4 items-center">
                          <img
                            src={calendarIcon}
                            className="w-3 h-3 md:h-4 md:w-4"
                          />
                          <span className="text-[10px] md:text-sm font-semibold text-white">
                            on going
                          </span>
                        </div>
                      </div>
                      <img
                        src={require("../../assets/btn-next.png")}
                        className="w-10 h-10 md:w-16 md:h-16 rounded-br-xl absolute bottom-0 right-0"
                      />
                    </div>
                  )}
                  {type === "directory" && (
                    <a href={`/directory?categoryId=${data._id}`}>
                      <div className="absolute bottom-0 flex flex-row items-center justify-between w-full rounded-bl-xl bg-[#00000066] backdrop-blur-lg">
                        <div className="py-2 px-4 md:p-4">
                          <span className="text-white font-bold text-xs md:text-xl">
                            {data?.name}
                          </span>
                        </div>
                        <div
                          className="p-2 md:p-4"
                          style={{
                            backgroundColor: "rgba(255, 255, 255, 0.12)",
                          }}
                        >
                          <svg
                            width="30"
                            height="22"
                            viewBox="0 0 28 28"
                            fill="none"
                          >
                            <path
                              d="M14.2007 1L23 9.99905M23 9.99905L14.2007 19M23 9.99905L1 10.0009"
                              stroke="white"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              strokeWidth={2}
                            />
                          </svg>
                        </div>
                      </div>
                    </a>
                  )}
                </div>
              );
          })
        ) : (
          <ImageRowSliderPlaceholder />
        )}

        {type === "whats-on" && (!datas || datas?.length < 1) && (
          <div className="flex flex-col items-center justify-center h-[calc(100%-10rem)] w-full mr-4 md:mr-20 mt-4">
            <img src={noEventIcon} />
            <span className="font-semibold text-base md:text-2xl text-center w-[300px]">
              No Happenings here yet!
            </span>
            <span className="text-sm md:text-xl text-center px-4 md:w-[600px]">
              It looks like there are no events scheduled at the moment. Check
              back soon for exciting happenings!
            </span>
          </div>
        )}
      </div>

      <div
        ref={sliderButtonRef}
        className="flex flex-col gap-y-8 md:flex-row md:justify-between xl:pr-6 mt-2 xl:mt-6 items-center"
      >
        <a
          href={`${
            type === "whats-on"
              ? "/whats-on"
              : type === "directory"
              ? "/directory"
              : "#"
          }`}
        >
          <div className="flex flex-row gap-x-2 md:gap-x-4 items-center justify-center cursor-pointer ps-4 md:ps-8 xl:ps-[120px]">
            <span className="text-[#50A85A] text-xs md:text-xl xl:text-2xl">
              Explore More
            </span>
            <img src={arrowRight} className="w-3 md:w-6" />
          </div>
        </a>
        {datas && datas?.length > 2 && (
          <div className="hidden xl:flex flex-row items-center cursor-pointer">
            <div
              onClick={() => {
                scrollLeft();
              }}
            >
              <ArrowLeftSlider
                color={
                  scrollLeftEnabled ? colorActiveScroll : colorInActiveScroll
                }
              />
            </div>
            <div
              onClick={() => {
                scrollRight();
              }}
            >
              <ArrowRightSlider
                color={
                  scrollRightEnabled ? colorActiveScroll : colorInActiveScroll
                }
              />
            </div>
          </div>
        )}
        {datas && datas?.length > 1 && (
          <div className="hidden md:flex flex-row items-center cursor-pointer">
            <div
              onClick={() => {
                scrollLeft();
              }}
            >
              <ArrowLeftSlider
                color={
                  scrollLeftEnabled ? colorActiveScroll : colorInActiveScroll
                }
              />
            </div>
            <div
              onClick={() => {
                scrollRight();
              }}
            >
              <ArrowRightSlider
                color={
                  scrollRightEnabled ? colorActiveScroll : colorInActiveScroll
                }
              />
            </div>
          </div>
        )}
        {datas && datas?.length > 1 && (
          <div className="flex flex-row justify-center md:hidden ">
            <div
              className={`px-6 py-3`}
              style={{
                background: scrollLeftEnabled
                  ? colorActiveScrollMobile
                  : colorInActiveScrollMobile,
              }}
              onClick={() => {
                scrollLeft();
              }}
            >
              <img src={arrowRightSmall} className="h-6" />
            </div>
            <div
              className={`px-6 py-3`}
              style={{
                background: scrollRightEnabled
                  ? colorActiveScrollMobile
                  : colorInActiveScrollMobile,
              }}
              onClick={() => {
                scrollRight();
              }}
            >
              <img src={arrowLeftSmall} className="h-6" />
            </div>
          </div>
        )}
      </div>

      {selectedEvent && type === "whats-on" && (
        <DetailEvent
          data={selectedEvent}
          onClose={() => setSelectedEvent(null)}
          onOpenTnc={() => {
            setOpenTnc(true);
          }}
        />
      )}

      {openTnc && (
        <TermsConditions content={urlTnc} onClose={() => setOpenTnc(false)} />
      )}
    </div>
  );
};

export default ImageRowSlider;
