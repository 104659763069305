export const extractTextFromTags = (htmlString, tag) => {
  if (!htmlString) return "";

  // Handle unordered lists <ul><li></li></ul>
  if (tag === "ul") {
    return (htmlString.match(/<li>(.*?)<\/li>/gi) || [])
      .map((match) => `• ${match.replace(/<\/?li>/g, "").trim()}`)
      .join("\n");
  }

  // Extract text from other tags
  const regex = new RegExp(`<${tag}>(.*?)<\/${tag}>`, "is");
  const match = htmlString.match(regex);
  return match ? match[1].replace(/<[^>]+>/g, "").trim() : "";
};

export const getInformationAsset = (filename) =>
  `https://d49qdhygt0dif.cloudfront.net/information/${filename}`;

export const getMediaAsset = (filename) =>
  `https://d49qdhygt0dif.cloudfront.net/media/${filename}`;

export const getPoiAsset = (filename) =>
  `https://d49qdhygt0dif.cloudfront.net/poi/${filename}`;
