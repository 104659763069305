import React from "react";
import { ReactComponent as X } from "../../assets/icons/detail-poi-close.svg";
import HtmlView from "../HtmlView";

const PrivacyPolicy = ({ privacyPolicy, onClose, onOpenTnc }) => {
  if (!privacyPolicy) return null;

  // Close modal when clicking outside
  const handleOutsideClick = (e) => {
    if (e.target.id === "modal-overlay") {
      onClose();
    }
  };

  const replaceText = (text) => {
    // Regex to find &lt;a&gt;here&lt;a&gt;
    const regex = /&lt;a&gt;(.*?)&lt;a&gt;/g;

    return text.replace(
      regex,
      (match, p1) =>
        `<span class="text-blue-600 hover:text-blue-800 cursor-pointer" onclick="(function(){window.reactSetIsChanged(true)})()"">${p1}</span>`
    );
  };

  window.reactSetIsChanged = (setIsChanged) => {
    onOpenTnc();
  };

  return (
    <div
      id="modal-overlay"
      className="fixed inset-0 z-40 bg-black bg-opacity-50 flex items-center justify-center"
      onClick={handleOutsideClick}
    >
      <div className="bg-white absolute w-[90%] md:w-1/2 h-[90%] overflow-y-auto rounded-2xl shadow-lg m-32">
        <div className="p-2 md:p-6">
          {/* Close Button */}
          <button
            className="absolute top-3 right-3 text-gray-400 hover:text-gray-600"
            onClick={onClose}
          >
            <X size={24} />
          </button>

          {/* Content Section */}
          <div className="flex flex-col md:flex-row gap-y-4 md:gap-x-4">
            <HtmlView htmlContent={replaceText(privacyPolicy)} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
