import { loadAdsignData } from "@adactiveasia/adsign-map";
import { useEffect, useState } from "react";
import { mapConfig } from "../../constants/mapConstant";
import { extractTextFromTags, getInformationAsset } from "../../helper";
import TermsConditions from "../TermsConditions";

const FormConsentCheckbox = ({ checked, onChange }) => {
  const [dataAdsign, setDataAdsign] = useState([]);
  const [contentTermsConditions, setContentTermsConditions] = useState(null);
  const [openTnc, setOpenTnc] = useState(false);
  const [urlTnc, setUrlTnc] = useState("");

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    if (
      dataAdsign &&
      dataAdsign.information &&
      dataAdsign.information.length > 0
    ) {
      const allPagesContent = dataAdsign.information
        .filter((info) => {
          return info.categoryName.toLowerCase().includes("all pages");
        })
        .sort((a, b) => a.name.localeCompare(b.name));

      const footerData = allPagesContent.filter((data) => {
        return data.name.toLowerCase().includes("footer");
      });

      const privacyPolicy = dataAdsign.information
        .filter((info) => {
          return info.name.toLowerCase().includes("data privacy policy");
        })
        .sort((a, b) => a.name.localeCompare(b.name));

      if (footerData) {
        allPagesContent.map((data) => {
          if (data.name.toLowerCase().includes("terms and condition")) {
            setContentTermsConditions(data.description);
          }
        });
      }

      if (privacyPolicy) {
        setUrlTnc(getInformationAsset(privacyPolicy?.[0]?.files?.[0]?.logo));
      }
    }
  }, [dataAdsign, dataAdsign.information]);

  const loadData = async () => {
    const adsignData = await loadAdsignData(mapConfig, true);
    if (adsignData) {
      setDataAdsign(adsignData);
    }
  };

  return (
    <div>
      <div className="flex items-center gap-2 text-xs md:text-sm text-gray-500">
        <input
          type="checkbox"
          className="size-5 rounded border-gray-300"
          checked={checked}
          onChange={onChange}
        />
        <p>
          When filling up this form, I consent to the collection, use,
          disclosure, and processing of my personal data by orchardgateway
          Private Limited for the purpose of communication{" "}
          <a
            className="text-gray-700 underline cursor-pointer"
            // href={extractTextFromTags(contentTermsConditions, "p") ?? "#"}
            onClick={() => setOpenTnc(true)}
            target="_blank"
          >
            Terms and conditions
          </a>
          .
        </p>
      </div>

      {openTnc && (
        <TermsConditions content={urlTnc} onClose={() => setOpenTnc(false)} />
      )}
    </div>
  );
};

export default FormConsentCheckbox;
