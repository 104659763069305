import React, { useEffect, useState } from "react";
import { useLocation, useMatch, useNavigate } from "react-router-dom";
import { AdsignMapContext } from "../../adsignMapProvider";
import { getPoiAsset } from "../../helper";
import noSearchIcon from "../../assets/no-search.svg";

const MenuIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    color="white"
  >
    <line x1="4" y1="6" x2="20" y2="6" />
    <line x1="4" y1="12" x2="20" y2="12" />
    <line x1="4" y1="18" x2="20" y2="18" />
  </svg>
);

const CloseIcon = (color = "white") => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    color={color}
  >
    <line x1="18" y1="6" x2="6" y2="18" />
    <line x1="6" y1="6" x2="18" y2="18" />
  </svg>
);

const ChevronDownIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
  >
    <path d="M6 9l6 6 6-6" />
  </svg>
);

const SearchIcon = ({ strokeWidth = 1, color = "white" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    stroke-width={strokeWidth}
    stroke-linecap="round"
    stroke-linejoin="round"
    color={color}
  >
    <circle cx="11" cy="11" r="8"></circle>
    <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
  </svg>
);

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [isOpenSearch, setIsOpenSearch] = useState(false);
  const [searchValue, setSearchValue] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [pois, setPois] = useState([]);

  const mapStore = React.useContext(AdsignMapContext);

  const navigation = [
    { name: "Home", href: "/" },
    {
      name: "About Us",
      href: "/about-us",
      //   dropdown: ["Product 1", "Product 2", "Product 3"],
    },
    {
      name: "What’s On",
      href: "/whats-on",
    },
    { name: "Store Directory", href: "/directory" },
    { name: "Floor Guide", href: "/floorguide" },
    { name: "Contact Us", href: "/contact-us" },
  ];

  const toggleDropdown = (index) => {
    setActiveDropdown(activeDropdown === index ? null : index);
  };

  useEffect(() => {
    if (mapStore?.pois && searchValue) {
      const poiList = mapStore.pois.filter((poi) => {
        if (searchValue) {
          const tag = mapStore?.tags?.filter((tag) =>
            tag.name.toLowerCase().includes(searchValue.toLowerCase())
          );
          return (
            poi.name.toLowerCase().includes(searchValue.toLowerCase()) ||
            (poi.categoryName &&
              poi.categoryName
                .toLowerCase()
                .includes(searchValue.toLowerCase())) ||
            (tag && tag.some((item) => poi.tags.includes(item._id)))
          );
        } else {
          return true;
        }
      });

      setPois(poiList);
    } else if (!searchValue || searchValue === "") {
      setPois([]);
    }
  }, [searchValue]);

  return (
    <div className="relative">
      <header className="bg-transaparent absolute top-0 z-30">
        <img
          src={require("../../assets/header.png")}
          className="w-full px-2 my-1"
          alt="orchard gateway logo"
        />
        <nav className="max-w-full px-4 md:px-6 xl:px-12">
          <div className="flex justify-between h-16 w-full items-center">
            {/* Logo */}
            <div className="flex-shrink-0 flex items-center">
              <span className="text-2xl font-bold text-gray-800">
                <img
                  src={require("../../assets/logo.png")}
                  className="w-48 md:w-56 lg:w-72 h-auto"
                  alt="orchard gateway logo"
                />
              </span>
            </div>

            {/* Desktop Navigation */}
            <div className="hidden lg:flex items-center">
              <div className="flex space-x-8">
                {navigation.map((item, index) => (
                  <div key={item.name} className="relative">
                    <button
                      onClick={() => {
                        if (item.dropdown) {
                          toggleDropdown(index);
                        } else {
                          navigate(item.href);
                        }
                      }}
                      className={`${
                        location.pathname === item.href
                          ? "text-[#50A85A]"
                          : "text-white"
                      } font-bold hover:text-[#50A85A] px-3 py-2 rounded-md text-sm flex items-center`}
                    >
                      {item.name}
                      {item.dropdown && (
                        <span className="ml-1">
                          <ChevronDownIcon />
                        </span>
                      )}
                    </button>
                    {location.pathname === item.href && (
                      <hr className="w-6 h-[2px] bg-[#50A85A] border-0" />
                    )}

                    {/* Desktop Dropdown */}
                    {item.dropdown && activeDropdown === index && (
                      <div className="absolute z-10 -ml-4 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                        <div className="py-1">
                          {item.dropdown.map((subItem) => (
                            <a
                              key={subItem}
                              href="#"
                              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                            >
                              {subItem}
                            </a>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>

            <div className="flex flex-row items-center gap-x-2">
              <div className="h-6 w-6" onClick={() => setIsOpenSearch(true)}>
                <SearchIcon strokeWidth={"2"} />
              </div>

              {/* Mobile menu button */}
              <div className="lg:hidden flex items-center bg-transparent">
                <button
                  onClick={() => setIsOpen(!isOpen)}
                  className="inline-flex items-center justify-center p-2 bg-transparent rounded-md text-gray-400"
                >
                  {isOpen ? <CloseIcon /> : <MenuIcon />}
                </button>
              </div>
            </div>
          </div>
        </nav>

        {/* Mobile menu */}
        {isOpen && (
          <div className="lg:hidden bg-white">
            <div className="px-2 pt-2 pb-3 space-y-1">
              {navigation.map((item, index) => (
                <div key={item.name}>
                  <button
                    onClick={() => {
                      if (item.dropdown) {
                        toggleDropdown(index);
                      } else {
                        navigate(item.href);
                      }
                    }}
                    className="w-full text-left text-gray-600 hover:text-gray-900 hover:bg-gray-50 block px-3 py-2 rounded-md text-base font-medium"
                  >
                    {item.name}
                  </button>

                  {/* Mobile Dropdown */}
                  {item.dropdown && activeDropdown === index && (
                    <div className="pl-4">
                      {item.dropdown.map((subItem) => (
                        <a
                          key={subItem}
                          href="#"
                          className="block px-3 py-2 text-sm text-gray-500 hover:text-gray-900 hover:bg-gray-50"
                        >
                          {subItem}
                        </a>
                      ))}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        )}
        {isOpenSearch && (
          <div class="fixed inset-0 z-40 bg-black bg-opacity-50 flex items-center justify-center">
            <div className="absolute right-0 top-0 w-full md:w-1/2 h-screen bg-white px-6 py-10 overflow-y-auto">
              <div className="flex flex-row gap-x-6">
                <div onClick={() => setIsOpenSearch(false)}>
                  <CloseIcon color={"black"} />
                </div>
                <div className="mb-8">
                  <span className="text-2XL text-[#1F284E] font-[Oplicio]">
                    SEARCH
                  </span>
                  <hr class="w-[48px] border-t-2 border-[#C03C8F]" />
                </div>
              </div>
              <div className="flex flex-row items-center gap-x-4 border-b border-gray-300 py-2">
                <a className="cursor-pointer">
                  <SearchIcon color={"#1F284E"} />
                </a>
                <input
                  type="text"
                  class="border-0 focus:outline-none w-full bg-white p-2 rounded textsm md:text-base text-[#919EAB]"
                  placeholder="What are you looking for?"
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                />
              </div>
              {pois && pois.length > 0 && (
                <div className="w-full h-max bg-[#EEECEA] p-3 mt-2">
                  <span className="text-[#556675] text-xs font-bold mb-2">
                    Results
                  </span>
                  <div className="flex flex-col gap-y-4">
                    {pois?.map((poi) => (
                      <a
                        href={`/directory?poiId=${poi._id}`}
                        className="rounded rounded-xl"
                      >
                        <div className="flex flex-row rounded rounded-xl">
                          <div className="h-[84px] w-[104px]">
                            <img
                              src={
                                poi.logo
                                  ? getPoiAsset(poi.logo)
                                  : require("../../assets/dummy-poi.png")
                              }
                              className="w-full h-full object-fill object-center"
                            />
                          </div>

                          <div className="flex flex-col w-full bg-white p-4 justify-between">
                            <span className="text-[#556675] text-xs font-bold">
                              {poi?.name}
                            </span>
                            <div className="flex flex-row justify-between w-full bg-white items-center">
                              <span className="text-[#556675] text-xs font-bold">
                                # {poi.location}
                              </span>
                              <div className="text-[8px] md:text-xs w-max rounded-full px-3 py-1 bg-[rgba(80,168,90,0.12)] text-[#50A85A] font-semibold">
                                {poi.categoryName ?? "-"}
                              </div>
                            </div>
                          </div>
                        </div>
                      </a>
                    ))}
                  </div>
                </div>
              )}
              {searchValue && (!pois || pois.length == 0) && (
                <div className="h-[calc(100%-10rem)]">
                  <div className="flex flex-col items-center justify-center h-[calc(100%-10rem)] px-4">
                    <img src={noSearchIcon} />
                    <span className="font-semibold text-base md:text-2xl text-center w-[300px]">
                      No Result Found
                    </span>
                    <span className="text-sm md:text-xl text-center w-[200px] md:w-[400px]">
                      Sorry, try adjusting your search to find what you are
                      looking for.
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </header>
      <div className="absolute z-0 inset-0 h-40 md:h-80 bg-gradient-to-b from-black/80 to-transparent flex items-center justify-center" />
    </div>
  );
};

export default Header;
