import React, { useState } from "react";
import { ReactComponent as X } from "../../assets/icons/detail-poi-close.svg";
import { Document, Page } from "react-pdf";
import { pdfjs } from "react-pdf";

pdfjs.GlobalWorkerOptions.workerSrc =
  window.location.origin + "/pdf.worker.min.mjs";

const TermsConditions = ({ content, onClose }) => {
  const [numPages, setNumPages] = useState(null);
  if (!content) return null;

  // Close modal when clicking outside
  const handleOutsideClick = (e) => {
    if (e.target.id === "modal-overlay") {
      onClose();
    }
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  return (
    <div
      id="modal-overlay"
      className="fixed inset-0 z-[90] bg-black bg-opacity-50 flex items-center justify-center"
      onClick={handleOutsideClick}
    >
      <div className="bg-white absolute w-[85%] md:w-max h-[90%] overflow-y-auto rounded-2xl shadow-lg m-32">
        <div className="p-2 md:p-6">
          {/* Close Button */}
          <button
            className="absolute top-3 right-3 text-gray-400 hover:text-gray-600"
            onClick={onClose}
          >
            <X size={24} />
          </button>

          {/* Content Section */}
          <div className="flex md:hidden flex-col gap-y-4 md:gap-x-4 mt-8">
            {content && (
              <Document
                file={content}
                options={{ cMapUrl: "cmaps/", cMapPacked: true }}
                onLoadSuccess={onDocumentLoadSuccess}
                className="flex flex-col items-center"
                loading={<p>Loading PDF...</p>}
                error={<p>Error loading PDF</p>}
              >
                {Array.from(new Array(numPages), (el, index) => (
                  <Page
                    key={`page_${index + 1}`}
                    pageNumber={index + 1}
                    renderTextLayer={false}
                    renderAnnotationLayer={false}
                    width={window.innerWidth}
                  />
                ))}
              </Document>
            )}
          </div>
          <div className="hidden md:flex flex-col gap-y-4 md:gap-x-4 mt-4">
            {content && (
              <Document
                file={content}
                options={{ cMapUrl: "cmaps/", cMapPacked: true }}
                onLoadSuccess={onDocumentLoadSuccess}
                className="flex flex-col items-center"
                loading={<p>Loading PDF...</p>}
                error={<p>Error loading PDF</p>}
              >
                {Array.from(new Array(numPages), (el, index) => (
                  <Page
                    key={`page_${index + 1}`}
                    pageNumber={index + 1}
                    renderTextLayer={false}
                    renderAnnotationLayer={false}
                  />
                ))}
              </Document>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsConditions;
