import React, { useEffect, useRef, useState } from "react";
import dummy1 from "../../assets/home.png";
import dummy3 from "../../assets/dummy-event.png";
// import arrowRightSlider from "../../assets/icons/arrow-right-slider.svg";
// import arrowLeftSlider from "../../assets/icons/arrow-left-slider.svg";
import ArrowRightSlider from "../../assets/icons/arrow-right-slider";
import ArrowLeftSlider from "../../assets/icons/arrow-left-slider";
import { getPoiAsset } from "../../helper";
import ImageLoader from "../ImageLoader";

const SlidesPlaceholder = () => {
  return (
    <div className={`flex-shrink-0 w-full snap-center `}>
      <div className="flex flex-col w-full justify-center gap-y-4">
        <div className="flex flex-row h-full gap-x-4 md:h-[320px]">
          <ImageLoader className="h-full w-full rounded-xl" />
          <ImageLoader className="h-full w-full rounded-xl" />
          <ImageLoader className="h-full w-full rounded-xl" />
        </div>
        <div className="flex flex-row h-full gap-x-4 md:h-[320px]">
          <ImageLoader className="h-full w-full rounded-xl" />
          <ImageLoader className="h-full w-full rounded-xl" />
        </div>
      </div>
    </div>
  );
};

const SliderCard = ({ slide }) => {
  return (
    <div className="relative h-full w-full">
      <a href={`/directory?categoryId=${slide._id}`}>
        <div className="absolute bottom-0 z-10 flex flex-row bg-[#00000066] backdrop-blur-lg items-center rounded rounded-bl-xl">
          <div className="py-4 pl-4 pr-8 md:py-2 md:pl-2 md:pr-8 xl:py-4 xl:pl-4 xl:pr-16 rounded rounded-bl-xl">
            <div className="text-white font-bold md:text-base xl:text-xl">
              {slide?.name ?? ""}
            </div>
          </div>
          <div
            className="p-2 xl:p-4"
            style={{
              backgroundColor: "rgba(255, 255, 255, 0.12)",
            }}
          >
            <svg width="28" height="28" viewBox="0 0 28 28" fill="none">
              <path
                d="M14.2007 1L23 9.99905M23 9.99905L14.2007 19M23 9.99905L1 10.0009"
                stroke="white"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </div>
      </a>
      {slide.logo ? (
        <ImageLoader
          src={getPoiAsset(slide?.logo ?? "")}
          className="h-full w-full rounded-xl"
        />
      ) : (
        <div className="bg-black object-cover rounded-md h-full w-full"></div>
      )}
    </div>
  );
};

const ContentSlider = ({ data }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const sliderRef = useRef(null);

  const [scrollRightColor, setScrollRightColor] = useState("#848484");
  const [scrollLeftColor, setScrollLeftColor] = useState("#D5D5D5");
  const [slides, setSlidesData] = useState([]);
  const [slideCount, setSlideCount] = useState(0);

  useEffect(() => {
    if (data) {
      const tempData = data.filter(
        (item) => item.name !== "Amenities" && item.name !== "Tenants"
      );
      const totalSlides =
        tempData.length > 5 ? Math.ceil(tempData.length / 5) : 1;
      const tempArray = [];
      let startIndex = 0;
      for (let index = 1; index < totalSlides + 1; index++) {
        tempArray.push(tempData.slice(startIndex, startIndex + 5 * index));
        startIndex += startIndex + 5 * index;
      }
      setSlidesData(tempArray);
    }
  }, [data]);

  const goToNextSlide = () => {
    if (currentIndex !== slides.length - 1) {
      const newIndex = currentIndex + 1;
      setCurrentIndex(newIndex);
      setScrollRightColor("#848484");
      setScrollLeftColor("#848484");

      if (sliderRef.current) {
        sliderRef.current.scrollTo({
          left: newIndex * sliderRef.current.offsetWidth,
          behavior: "smooth",
        });
      }
    }
    if (currentIndex === slides.length - 2) {
      setScrollRightColor("#D5D5D5");
    }
  };

  const goToPrevSlide = () => {
    const newIndex = currentIndex === 0 ? slides.length - 1 : currentIndex - 1;
    if (currentIndex > 0) {
      setCurrentIndex(newIndex);
      setScrollRightColor("#848484");
      setScrollLeftColor("#848484");

      if (sliderRef.current) {
        sliderRef.current.scrollTo({
          left: newIndex * sliderRef.current.offsetWidth,
          behavior: "smooth",
        });
      }
    }
    if (currentIndex - 1 === 0) {
      setScrollLeftColor("#D5D5D5");
    }
  };

  return (
    <div className="w-full relative">
      <div
        ref={sliderRef}
        className="flex overflow-x-hidden snap-x snap-mandatory w-full"
      >
        {slides && slides.length > 0 ? (
          slides?.map((slide) => {
            return (
              <div
                key={slide.id}
                className={`flex-shrink-0 w-full snap-center `}
              >
                <div className="flex flex-col w-full justify-center gap-y-4">
                  <div className="flex flex-row h-full gap-x-4 md:h-[320px]">
                    {slide?.[0] && <SliderCard slide={slide[0]} />}
                    {slide?.[1] && <SliderCard slide={slide[1]} />}
                    {slide?.[2] && <SliderCard slide={slide[2]} />}
                  </div>
                  <div className="flex flex-row h-full gap-x-4 md:h-[320px]">
                    {slide?.[3] && <SliderCard slide={slide[3]} />}
                    {slide?.[4] && <SliderCard slide={slide[4]} />}
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <SlidesPlaceholder />
        )}
      </div>

      {slides && slides?.length > 1 && (
        <div className="flex flex-row gap-x-6 justify-center mt-8 gap-2">
          <div onClick={goToPrevSlide}>
            <ArrowLeftSlider
              color={slides.length > 1 ? scrollLeftColor : "#D5D5D5"}
            />
          </div>
          <div onClick={goToNextSlide}>
            <ArrowRightSlider
              color={slides.length > 1 ? scrollRightColor : "#D5D5D5"}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ContentSlider;
