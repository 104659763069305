import React from "react";

const TextInput = ({ label, placeholder, onChange, lines }) => {
  return (
    <div className="mb-2 md:mb-4">
      <label className="block text-black font-semibold text-sm">{label}</label>
      {lines ? (
        <textarea
          rows={lines}
          placeholder={placeholder}
          className="w-full p-2 md:p-3 border-b text-sm md:text-base text-gray-600 focus:outline-none placeholder-gray-400 resize-none"
          onChange={onChange}
        />
      ) : (
        <input
          type="text"
          placeholder={placeholder}
          className="w-full p-2 md:p-3 border-b text-sm md:text-base text-gray-600 focus:outline-none placeholder-gray-400"
          onChange={onChange}
        />
      )}
    </div>
  );
};

export default TextInput;
